import React from 'react'
import { IconProps } from '../types'

export const UTCIcon = ({ width, height }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 224 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.3966 19.05C20.915 19.05 17.9671 18.3045 15.5528 16.8137C13.1386 15.3228 11.7027 13.381 11.2453 10.9882H7.16649V18.7187H1.7535V0.303695H7.16649V7.53717H11.3597C11.9442 5.29166 13.4181 3.47869 15.7815 2.09826C18.1704 0.699419 21.042 0 24.3966 0C28.2594 0 31.436 0.89268 33.9265 2.67804C36.417 4.4634 37.6622 6.74572 37.6622 9.52499C37.6622 12.3043 36.417 14.5866 33.9265 16.3719C31.436 18.1573 28.2594 19.05 24.3966 19.05ZM18.793 13.8595C20.2161 15.0007 22.084 15.5713 24.3966 15.5713C26.7092 15.5713 28.577 15.0007 30.0002 13.8595C31.4233 12.7184 32.1349 11.2735 32.1349 9.52499C32.1349 7.77644 31.4233 6.33159 30.0002 5.19043C28.577 4.04927 26.7092 3.47869 24.3966 3.47869C22.0586 3.47869 20.178 4.04927 18.7549 5.19043C17.3571 6.33159 16.6583 7.77644 16.6583 9.52499C16.6583 11.2735 17.3698 12.7184 18.793 13.8595Z"
        fill="#474747"
      />
      <path
        d="M45.0562 18.7187H39.0333L46.5048 11.43L39.262 5.38369H45.2087L50.8504 10.2152V5.38369H55.7297V10.2152L61.3714 5.38369H67.3181L60.0754 11.43L67.5468 18.7187H61.5239L56.6065 13.5835L55.7297 14.2737V18.7187H50.8504V14.2737L49.9737 13.5835L45.0562 18.7187Z"
        fill="#474747"
      />
      <path
        d="M74.8277 18.7187H69.9865V5.38369H74.8277V10.3256H82.4516V5.38369H87.2928V18.7187H82.4516V13.3902H74.8277V18.7187Z"
        fill="#474747"
      />
      <path
        d="M92.0988 5.38369H96.94V9.96673H103.23C105.263 9.96673 106.838 10.3809 107.957 11.2091C109.075 12.0374 109.634 13.0773 109.634 14.3289C109.634 15.5621 109.062 16.602 107.918 17.4487C106.775 18.2953 105.212 18.7187 103.23 18.7187H92.0988V5.38369ZM102.506 12.8932H96.94V15.7645H102.506C103.166 15.7645 103.687 15.6357 104.068 15.378C104.475 15.1019 104.678 14.7522 104.678 14.3289C104.678 13.9056 104.475 13.5651 104.068 13.3074C103.687 13.0313 103.166 12.8932 102.506 12.8932ZM116.915 18.7187H112.035V5.38369H116.915V18.7187Z"
        fill="#474747"
      />
      <path
        d="M126.344 18.7187H121.694V5.38369H126.535V13.4454L134.121 5.38369H139V18.7187H134.159V10.3532L126.344 18.7187ZM137.018 1.62891C135.366 3.24862 133.142 4.05847 130.347 4.05847C127.577 4.05847 125.34 3.24862 123.638 1.62891L125.772 0.331305C126.891 1.47246 128.415 2.04304 130.347 2.04304C132.227 2.04304 133.739 1.47246 134.883 0.331305L137.018 1.62891Z"
        fill="#474747"
      />
      <path
        d="M10.3076 46.3273H5.94666V34.3562H0V31.5954H16.2237V34.3562H10.3076V46.3273Z"
        fill="#474747"
      />
      <path
        d="M28.4334 44.98C27.0103 46.0549 25.1196 46.5924 22.7612 46.5924C20.4029 46.5924 18.5122 46.0549 17.089 44.98C15.6862 43.8904 14.9848 42.5578 14.9848 40.9823C14.9848 39.4068 15.6862 38.0816 17.089 37.0067C18.5122 35.9318 20.4029 35.3943 22.7612 35.3943C25.1196 35.3943 27.0103 35.9318 28.4334 37.0067C29.8566 38.0816 30.5681 39.4068 30.5681 40.9823C30.5681 42.5578 29.8566 43.8904 28.4334 44.98ZM20.0166 43.2131C20.6875 43.8021 21.6024 44.0966 22.7612 44.0966C23.9201 44.0966 24.8349 43.8021 25.5059 43.2131C26.1971 42.6094 26.5427 41.8658 26.5427 40.9823C26.5427 40.1135 26.1971 39.3847 25.5059 38.7957C24.8349 38.192 23.9201 37.8901 22.7612 37.8901C21.6024 37.8901 20.6875 38.192 20.0166 38.7957C19.3457 39.3847 19.0103 40.1135 19.0103 40.9823C19.0103 41.8658 19.3457 42.6094 20.0166 43.2131Z"
        fill="#474747"
      />
      <path
        d="M41.8582 46.5924C40.0081 46.5924 38.4935 46.0476 37.3144 44.9579V50.3913H33.4414V35.6594H37.3144V37.0067C38.4732 35.9318 39.9878 35.3943 41.8582 35.3943C43.7896 35.3943 45.3551 35.8949 46.5546 36.8962C47.7744 37.8828 48.3843 39.2448 48.3843 40.9823C48.3843 42.7198 47.7744 44.0892 46.5546 45.0905C45.3551 46.0917 43.7896 46.5924 41.8582 46.5924ZM40.6384 44.0966C41.7566 44.0966 42.6511 43.8094 43.322 43.2352C44.0133 42.6609 44.3589 41.91 44.3589 40.9823C44.3589 40.0694 44.0133 39.3258 43.322 38.7515C42.6511 38.1773 41.7566 37.8901 40.6384 37.8901C40.0082 37.8901 39.3779 38.0079 38.7477 38.2435C38.1174 38.4791 37.6397 38.7662 37.3144 39.1049V42.8818C37.6397 43.2204 38.1174 43.5076 38.7477 43.7432C39.3982 43.9788 40.0285 44.0966 40.6384 44.0966Z"
        fill="#474747"
      />
      <path
        d="M62.5955 35.6594V38.111H55.0936V46.3273H51.2206V35.6594H62.5955Z"
        fill="#474747"
      />
      <path
        d="M77.572 44.98C76.1489 46.0549 74.2582 46.5924 71.8998 46.5924C69.5415 46.5924 67.6508 46.0549 66.2276 44.98C64.8248 43.8904 64.1234 42.5578 64.1234 40.9823C64.1234 39.4068 64.8248 38.0816 66.2276 37.0067C67.6508 35.9318 69.5415 35.3943 71.8998 35.3943C74.2582 35.3943 76.1489 35.9318 77.572 37.0067C78.9952 38.0816 79.7067 39.4068 79.7067 40.9823C79.7067 42.5578 78.9952 43.8904 77.572 44.98ZM69.1552 43.2131C69.8261 43.8021 70.741 44.0966 71.8998 44.0966C73.0587 44.0966 73.9735 43.8021 74.6445 43.2131C75.3357 42.6094 75.6813 41.8658 75.6813 40.9823C75.6813 40.1135 75.3357 39.3847 74.6445 38.7957C73.9735 38.192 73.0587 37.8901 71.8998 37.8901C70.741 37.8901 69.8261 38.192 69.1552 38.7957C68.4843 39.3847 68.1489 40.1135 68.1489 40.9823C68.1489 41.8658 68.4843 42.6094 69.1552 43.2131Z"
        fill="#474747"
      />
      <path
        d="M92.1862 46.3273H82.58V35.6594H91.9117C93.3348 35.6594 94.4428 35.9244 95.2357 36.4545C96.0286 36.9846 96.4251 37.6324 96.4251 38.3981C96.4251 39.0166 96.1913 39.5393 95.7237 39.9663C95.2561 40.3933 94.6563 40.6804 93.9244 40.8277C94.7376 40.9602 95.4085 41.2694 95.9371 41.7553C96.4657 42.2413 96.73 42.8008 96.73 43.4339C96.73 44.2733 96.3234 44.9653 95.5102 45.5101C94.7173 46.0549 93.6093 46.3273 92.1862 46.3273ZM91.2408 44.0303C91.7084 44.0303 92.0743 43.9419 92.3386 43.7653C92.6233 43.5886 92.7656 43.3456 92.7656 43.0364C92.7656 42.7566 92.6233 42.521 92.3386 42.3296C92.0743 42.1382 91.7084 42.0425 91.2408 42.0425H86.453V44.0303H91.2408ZM91.1188 39.7454C91.5254 39.7454 91.8507 39.6644 92.0947 39.5025C92.359 39.3258 92.4911 39.1123 92.4911 38.862C92.4911 38.5969 92.3691 38.3834 92.1252 38.2214C91.8812 38.0447 91.5458 37.9564 91.1188 37.9564H86.453V39.7454H91.1188Z"
        fill="#474747"
      />
      <path
        d="M100.449 35.6594H104.322V39.3258H109.353C110.98 39.3258 112.24 39.6571 113.135 40.3197C114.029 40.9823 114.477 41.8142 114.477 42.8155C114.477 43.8021 114.019 44.634 113.104 45.3113C112.189 45.9887 110.939 46.3273 109.353 46.3273H100.449V35.6594ZM108.774 41.667H104.322V43.964H108.774C109.303 43.964 109.719 43.861 110.024 43.6548C110.35 43.434 110.512 43.1542 110.512 42.8155C110.512 42.4769 110.35 42.2044 110.024 41.9983C109.719 41.7774 109.303 41.667 108.774 41.667ZM120.301 46.3273H116.398V35.6594H120.301V46.3273Z"
        fill="#474747"
      />
      <path
        d="M127.845 46.3273H124.124V35.6594H127.997V42.1087L134.066 35.6594H137.97V46.3273H134.097V39.635L127.845 46.3273ZM136.384 32.6555C135.062 33.9513 133.283 34.5992 131.047 34.5992C128.831 34.5992 127.042 33.9513 125.68 32.6555L127.388 31.6174C128.282 32.5304 129.502 32.9868 131.047 32.9868C132.551 32.9868 133.761 32.5304 134.676 31.6174L136.384 32.6555Z"
        fill="#474747"
      />
      <path
        d="M17.0471 76.6527V73.936H1.25032V59.204H5.58072V71.1751H15.1869V59.204H19.5478V71.1751H21.3775V76.6527H17.0471Z"
        fill="#474747"
      />
      <path
        d="M30.7447 74.201C28.4677 74.201 26.5871 73.6857 25.103 72.655C23.6188 71.6242 22.8768 70.2696 22.8768 68.591C22.8768 67.0154 23.5884 65.6902 25.0115 64.6153C26.455 63.5404 28.2847 63.003 30.5007 63.003C32.6964 63.003 34.4753 63.5478 35.8375 64.6374C37.1996 65.7123 37.8807 67.1259 37.8807 68.8781V69.4965H26.9327C27.0547 70.2033 27.4918 70.7923 28.244 71.2635C28.9963 71.7347 29.9721 71.9703 31.1716 71.9703C31.8222 71.9703 32.5236 71.8746 33.2758 71.6831C34.0484 71.4917 34.6583 71.234 35.1056 70.9101L36.8133 72.7212C35.3292 73.7078 33.3063 74.201 30.7447 74.201ZM34.1297 67.5971C34.0687 66.9933 33.7333 66.4485 33.1233 65.9626C32.5338 65.4767 31.6596 65.2338 30.5007 65.2338C29.4029 65.2338 28.549 65.4767 27.9391 65.9626C27.3292 66.4338 26.9734 66.9786 26.8717 67.5971H34.1297Z"
        fill="#474747"
      />
      <path
        d="M44.5511 73.936H40.6782V63.268H44.5511V67.2216H50.6503V63.268H54.5232V73.936H50.6503V69.6732H44.5511V73.936Z"
        fill="#474747"
      />
      <path
        d="M64.9856 73.936H61.0822V65.7197H56.9653V63.268H69.1026V65.7197H64.9856V73.936Z"
        fill="#474747"
      />
      <path
        d="M79.9481 74.201C78.098 74.201 76.5834 73.6562 75.4042 72.5666V78H71.5313V63.268H75.4042V64.6153C76.5631 63.5404 78.0777 63.003 79.9481 63.003C81.8795 63.003 83.4449 63.5036 84.6444 64.5049C85.8643 65.4914 86.4742 66.8535 86.4742 68.591C86.4742 70.3285 85.8643 71.6979 84.6444 72.6991C83.4449 73.7004 81.8795 74.201 79.9481 74.201ZM78.7283 71.7052C79.8464 71.7052 80.741 71.4181 81.4119 70.8438C82.1031 70.2696 82.4487 69.5186 82.4487 68.591C82.4487 67.678 82.1031 66.9344 81.4119 66.3602C80.741 65.7859 79.8464 65.4988 78.7283 65.4988C78.098 65.4988 77.4678 65.6166 76.8375 65.8522C76.2073 66.0878 75.7295 66.3749 75.4042 66.7136V70.4904C75.7295 70.8291 76.2073 71.1162 76.8375 71.3518C77.4881 71.5874 78.1183 71.7052 78.7283 71.7052Z"
        fill="#474747"
      />
    </svg>
  )
}
