export const elevatorClassesMock = [
  {
    id: 1,
    class: 'Premium',
    url: '/premium',
  },
  {
    id: 2,
    class: 'Business',
    url: '/business',
  },
  {
    id: 3,
    class: 'Comfort',
    url: '/comfort',
  },
]
