import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './styles.module.scss'

export const BreadCrumbs = () => {
  const location = useLocation()
  const pathname =
    location.pathname[1].toUpperCase() + location.pathname.slice(2)

  const handleClickBreadcrumb = () => {
    setTimeout(() => {
      document.querySelector('#elevator-classes')?.scrollIntoView({
        behavior: 'smooth',
      })
    }, 3100)
    location.pathname = '/'
  }

  return (
    <div className={styles.breadCrumbs}>
      <Link to="/" onClick={handleClickBreadcrumb}>
        Виды лифтов
      </Link>
      <span className={styles.location}>{pathname}</span>
    </div>
  )
}
