import React from 'react'
import { Link } from 'react-router-dom'

import { TitleH2 } from '../../../../fields'
import styles from './styles.module.scss'

export const SBLDesign = () => {
  return (
    <div className={styles.sbldesign} id="sbl-design">
      <TitleH2 color="black">SBL Design</TitleH2>
      <div className={styles.sbldesign_text}>
        <p>
          Технологическое лифтовое ателье <br />
          Research and Development <br />
          Эксклюзивные лифты <br />
          Разработка мультимедиа систем для лифтов <br />
          Внедрение новых лифтовых технологий
        </p>
        <Link
          to="/sbl-design"
          className="button_black"
          preventScrollReset={true}>
          <span className="button_circle_holder">
            <span className="button_circle" />
          </span>
          <span className="button__text">Подробнее</span>
        </Link>
      </div>
    </div>
  )
}
