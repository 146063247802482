import ElevatorOne from '../../../../assets/images/presentation-elevators/elevator-01.png'
import ElevatorTwo from '../../../../assets/images/presentation-elevators/elevator-02.png'
import ElevatorThree from '../../../../assets/images/presentation-elevators/elevator-03.png'
import ElevatorFour from '../../../../assets/images/presentation-elevators/elevator-04.png'

export const presentationMock = [
  {
    id: 1,
    img: ElevatorOne,
    text: '00-1  ',
  },
  {
    id: 2,
    img: ElevatorTwo,
    text: '00-2',
  },
  {
    id: 3,
    img: ElevatorThree,
    text: '00-3',
  },
  {
    id: 4,
    img: ElevatorFour,
    text: '00-4',
  },
]
