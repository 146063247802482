import React from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from '../../hook/app'
import { FileIcon } from '../../assets/icons'
import { setScrollPage, setShowModal, setThxModal } from '../../store/appSlice'
import styles from './styles.module.scss'

export const ValidationSchemaMob: React.FC = () => {
  const form = React.useRef() as React.MutableRefObject<HTMLFormElement>
  const dispatch = useAppDispatch()
  const { register, handleSubmit } = useForm()

  const onSubmit = async (data: any) => {
    const formData = new FormData()
    formData.append('file', data.file[0])
    formData.append('firstName', data.firstName)
    formData.append('email', data.email)
    formData.append('phone', data.phone)
    formData.append('company', data.company)

    fetch('http://localhost:3002/api/sendmail', {
      method: 'POST',
      body: formData,
    })
      .then(res => {
        if ((data.firstName && (data.email || data.phone)).length > 1) {
          if (data.err) {
            console.log('err: ', data.err)
          } else {
            res.json()
            dispatch(setThxModal(true))
            dispatch(setShowModal(false))
          }
        } else {
          alert('Введите данные')
        }
      })
      .catch((err: any) => console.log('error in send  mail', err))
  }

  const hideModal = () => {
    dispatch(setShowModal(false))
    dispatch(setScrollPage(true))
  }

  return (
    <div>
      <form
        ref={form}
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
        method="post">
        <div className={styles.input_name_wrap}>
          <label htmlFor="firstName">Меня зовут</label>
          <input
            {...register('firstName', {
              required: true,
            })}
            className={styles.input_name}
            placeholder="Имя и Отчество"
          />
        </div>
        <div className={styles.input_company_wrap}>
          <label htmlFor="company">и я работаю в компании</label>
          <input
            {...register('company')}
            className={styles.input_company}
            placeholder="Название компании"
          />
        </div>
        <div className={styles.input_contact_wrap}>
          <label htmlFor="text">Пожалуйста, свяжитесь со мной с помощью</label>
          <input
            {...register('email', {
              required: true,
            })}
            className={styles.input_contact}
            placeholder="Почта или номер телефона"
          />
        </div>
        <div className={styles.input_phone_wrap}>
          <label htmlFor="text">Номер</label>
          <input
            {...register('phone', { required: true })}
            className={styles.input_phone}
            placeholder="Номер"
          />
        </div>
        <div className={styles.input_file_wrap}>
          <label htmlFor="file-input">
            <FileIcon width={24} height={24} />
          </label>
          <input
            type="file"
            {...register('file')}
            className={styles.input_file}
          />
        </div>
        <div className={styles.modal_footer}>
          <button type="submit" className="button_white">
            <span className="button_circle_holder">
              <span className="button_circle" />
            </span>
            <span className="button__text">Отправить заявку</span>
          </button>
          <span className={styles.privacy_policy}>
            Нажимая на кнопку вы принимаете
            <Link to="/privacy-policy" onClick={hideModal}>
              политику конфиденциальности
            </Link>
          </span>
        </div>
      </form>
    </div>
  )
}
