import React from 'react'

import { Container } from '../../../../components'
import { TitleH2, TitleH3, Button, Description } from '../../../../fields'
import { elevatorClassesMock } from './elevatorClassesMock'

import styles from './styles.module.scss'

export const ElevatorClasses = () => {
  const handleClick = () =>
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, 10)

  return (
    <div className={styles.elevator_classes_wrap} id="elevator-classes">
      <Container>
        <div className={styles.elevator_classes_text}>
          <TitleH2 color="white">Классы лифтов</TitleH2>
          <Description color="white">
            Лифты классифицируются по типу зданий, в которых они
            устанавливаются. Наша компания представляет услуги в 3 классах
          </Description>
        </div>
        {elevatorClassesMock.map((item: any) => (
          <div className={styles.elevator_classes} key={item.id}>
            <div className={styles.class}>
              <TitleH3>{item.class}</TitleH3>
            </div>
            <Button to={item.url} color="white" onClick={handleClick}>
              Посмотреть
            </Button>
          </div>
        ))}
      </Container>
    </div>
  )
}
