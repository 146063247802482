import React, { useEffect } from 'react'

import { Container } from '../../components'
import { TitleH2 } from '../../fields'
import aboutImg from '../../assets/images/about/award.png'
import styles from './styles.module.scss'

export const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <div className={styles.about_wrap}>
        <TitleH2 color="black">О компании</TitleH2>
        <div className={styles.about}>
          <div className={styles.about_text}>
            <div className={styles.about_text_info}>
              <p>
                Компания «Бюро Специальных Проектов» берет свое начало в 2009.
                За свою долгую историю мы создали комплексную компанию,
                работающую в сфере монтажа и эксплуатации лифтового
                оборудования, а также имеющую компетенции в выполнии проектных и
                строительных работ повышенной сложности.
              </p>
              <p>
                Одним из конкурентных преимуществ компании является выполнение
                приоритетных проектов Заказчика в сложных условиях, сжатых
                сроках и без потерь качества.
              </p>
              <p>
                Является действующим членом ООР СоюзМаш России, выполняет задачи
                ОПК Российской Федерации.
              </p>
            </div>
            <div className={styles.about_number_wrap}>
              <span className={styles.about_number}>
                13
                <span>лет на рынке</span>
              </span>
              <span className={styles.about_number}>
                2014
                <span>работаем с ГОЗ</span>
              </span>
              <span className={styles.about_number}>
                1<span>место на REW 2017</span>
              </span>
            </div>
          </div>
          <div className={styles.about_wrap_img}>
            <img src={aboutImg} alt="" className={styles.about_img} />
          </div>
        </div>
      </div>
    </Container>
  )
}
