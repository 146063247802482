import React from "react";
import { IconProps } from "./types";

export const FileIcon = ({ width, height }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36 17.9392L19.9256 33.0994C17.9563 34.9566 15.2855 36 12.5006 36C9.71564 36 7.04478 34.9566 5.07554 33.0994C3.10631 31.2421 2 28.7232 2 26.0967C2 23.4701 3.10631 20.9512 5.07554 19.0939L21.15 3.93375C22.4628 2.69559 24.2434 2 26.1 2C27.9566 2 29.7372 2.69559 31.05 3.93375C32.3628 5.17191 33.1004 6.85121 33.1004 8.60223C33.1004 10.3532 32.3628 12.0326 31.05 13.2707L14.9581 28.4309C14.3017 29.05 13.4114 29.3978 12.4831 29.3978C11.5548 29.3978 10.6645 29.05 10.0081 28.4309C9.35165 27.8118 8.98288 26.9722 8.98288 26.0967C8.98288 25.2211 9.35165 24.3815 10.0081 23.7624L24.8581 9.77347"
        stroke="#EDEDED"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
