import businessImg1 from '../../../assets/images/elevator-classes/business/business-01.png'
import businessImg2 from '../../../assets/images/elevator-classes/business/business-02.png'
import businessImg3 from '../../../assets/images/elevator-classes/business/business-03.png'
import businessImg4 from '../../../assets/images/elevator-classes/business/business-04.png'
import businessImg5 from '../../../assets/images/elevator-classes/business/business-05.png'
import businessImg6 from '../../../assets/images/elevator-classes/business/business-06.png'
import businessImg7 from '../../../assets/images/elevator-classes/business/business-07.png'

export const businessMock = [
  {
    id: 1,
    elevator_name: 'FUJI HD®',
    image: businessImg1,
    walls:
      'Нержавеющая сталь + вставка из декоративной черной нержавеющей стали',
    ceiling: 'Подвесной, светодиодное освещение',
    floor: 'Керрамогранит',
  },
  {
    id: 2,
    elevator_name: 'FUJI HD®',
    image: businessImg2,
    walls:
      'Нержавеющая сталь + вставка из декоративной текстурированной нержавеющей стали',
    ceiling: 'Монолитный, светодиодное освещение',
    floor: 'Кварцвиниловое покрытие',
  },
  {
    id: 3,
    elevator_name: 'FUJI HD®',
    image: businessImg3,
    walls: 'Металлопласт деревянной фактуры 2-х типов',
    ceiling: 'Подвесной, светодиодное освещение',
    floor: 'Керрамогранит',
  },
  {
    id: 4,
    elevator_name: 'FUJI HD®',
    image: businessImg4,
    walls:
      'Нержавеющая сталь + вставка металлопласта  деревянной фактуры со светодиодной подсветкой',
    ceiling: 'Подвесной, светодиодное освещение',
    floor: 'Rеррамогранит',
  },
  {
    id: 5,
    elevator_name: 'Wellmaks NOVA®',
    image: businessImg5,
    walls: 'Нержавеющая сталь + вставка из декоративной текстурированной стали',
    ceiling: 'Подвесной, светодиодное освещение',
    floor: 'Кварцвиниловое покрытие',
  },
  {
    id: 6,
    elevator_name: 'Wellmaks NOVA®',
    image: businessImg6,
    walls: 'Нержавеющая сталь + вставка металлопласта деревянной фактуры',
    ceiling: 'Подвесной, светодиодное освещение',
    floor: 'Кварцвиниловое покрытие',
  },
  {
    id: 7,
    elevator_name: 'Wellmaks NOVA®',
    image: businessImg7,
    walls: 'Нержавеющая сталь + вставка металлопласта  деревянной фактуры ',
    ceiling: 'Подвесной, светодиодное освещение',
    floor: 'Керамогранит',
  },
]
