import XiziXLine from '../../../assets/images/elevator-classes/premium/xizi-x-line.png'
import XiziHeritage from '../../../assets/images/elevator-classes/premium/xizi-heritage.png'
import XiziXLine2 from '../../../assets/images/elevator-classes/premium/xizi-x-line-02.png'
import XiziHeritage2 from '../../../assets/images/elevator-classes/premium/xizi-heritage-02.png'

export const premiumMock = [
  {
    id: 1,
    elevator_name: 'FUJI HD®',
    image: XiziXLine,
    image2: XiziXLine2,
    design: 'NOIR',
    walls:
      'Нержавеющая сталь с титановым покрытием, вставки из натурального камня',
    ceiling:
      'Нержавеющая сталь с титановым покрытием, полупрозрачный мрамор, светодиодная скрытая подсветка купе',
    floor: 'Мрамор',
  },
  {
    id: 2,
    elevator_name: 'FUJI HD®',
    image: XiziHeritage,
    image2: XiziHeritage2,
    design: 'Heritage',
    walls:
      'Нержавеющая сталь с анодированным темным бронзовым покрытием, панели отделанные натуральным шпоном, с огнеупорной пропиткой G1',
    ceiling:
      'Подвесной из матового безопасного стекла со встакой из нержавеющей стали с темным бронзовым покрытием, светодиодная освещение',
    floor: 'Мрамор, цельная плита',
  },
]
