import React from 'react'
import { Container, BreadCrumbs, ModalForm } from '../../../components'
import { ButtonModal } from '../../../fields'
import { comfortMock } from './comfortMock'
import { ElevatorsClassesTypes } from '../elevatorsClassesTypes'
import styles from './styles.module.scss'

export const Comfort = () => {
  return (
    <Container>
      <div className={styles.elevator_info_wrap}>
        <BreadCrumbs />
        <div className={styles.elevator_info}>
          <span className={`${styles.elevator_page} ${styles.elevator_name}`}>
            Comfort
          </span>
          <div className={styles.elevator_info_text}>
            <h5 className={styles.elevator_title}>
              Лифты Comfort класса производства Wellmaks®
            </h5>
          </div>
        </div>
        <div className={styles.elevator_wrap}>
          {comfortMock.map((item: ElevatorsClassesTypes) => (
            <div key={item.id}>
              <span className={styles.elevator_name}>{item.elevator_name}</span>

              <div className={styles.elevator}>
                <img src={item.image} alt="" className={styles.elevator_img} />
                <div className={styles.elevator_text_wrap}>
                  <div className={styles.elevator_text}>
                    <span className={styles.type}>Дизайн</span>
                    <span className={styles.type_info}>{item.design}</span>
                  </div>
                  <div className={styles.elevator_text}>
                    <span className={styles.type}>Стены купе</span>
                    <span className={styles.type_info}>{item.walls}</span>
                  </div>
                  <div className={styles.elevator_text}>
                    <span className={styles.type}>Потолок</span>
                    <span className={styles.type_info}>{item.ceiling}</span>
                  </div>
                  <div className={styles.elevator_text}>
                    <span className={styles.type}>Пол</span>
                    <span className={styles.type_info}>{item.floor}</span>
                  </div>
                  <ButtonModal />
                  <ModalForm />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}
