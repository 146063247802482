import comfortImg1 from '../../../assets/images/elevator-classes/comfort/comfort-01.png'
import comfortImg2 from '../../../assets/images/elevator-classes/comfort/comfort-02.png'
import comfortImg3 from '../../../assets/images/elevator-classes/comfort/comfort-03.png'

export const comfortMock = [
  {
    id: 1,
    elevator_name: 'Wellmaks NOVA®',
    image: comfortImg1,
    walls: 'Нержавеющая сталь',
    ceiling: 'Подвесной, светодиодное освещение',
    floor: 'Кварцвиниловое покрытие',
  },
  {
    id: 2,
    elevator_name: 'Wellmaks NOVA®',
    image: comfortImg2,
    walls: 'Высококачественная порошковая покраска neokem',
    ceiling: 'Светодиодное освещение',
    floor: 'Износостойкое покрытие',
  },
  {
    id: 3,
    elevator_name: 'Wellmaks NOVA®',
    image: comfortImg3,
    walls: 'Высококачественная порошковая покраска neokem',
    ceiling: 'Светодиодное освещение',
    floor: 'Износостойкое покрытие',
  },
]
