import React from 'react'

import { setScrollPage, setShowModal } from '../../store/appSlice'
import { useAppSelector, useAppDispatch } from '../../hook/app'
import { FieldsProps } from '../types'

export const ButtonModal = ({ className, color = 'black' }: FieldsProps) => {
  const app = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()

  const handleClick = () => {
    dispatch(setScrollPage(!app.scrollPage))
    dispatch(setShowModal(true))
  }

  return (
    <button
      onClick={handleClick}
      className={`button_${color} ${className ? className : ''}`}>
      <span className="button_circle_holder">
        <span className="button_circle" />
      </span>
      <span className="button__text">Оставить заявку</span>
    </button>
  )
}
