import React from "react";
import { IconProps } from "../types";

export const TkpIcon = ({ width, height }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 175 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_327_47)">
        <rect y="36" width="12" height="12" fill="#474747" />
        <rect x="16" y="36" width="12" height="12" fill="#474747" />
        <rect x="32" y="36" width="12" height="12" fill="#474747" />
        <rect x="32" y="18" width="12" height="12" fill="#474747" />
        <rect x="48" width="12" height="48" fill="#474747" />
        <rect x="64" width="12" height="12" fill="#474747" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M131 0H141H143H163H166H175V48H163V12H143V48H131V0Z"
          fill="#474747"
        />
        <rect x="32" width="12" height="12" fill="#474747" />
        <rect x="16" y="18" width="12" height="12" fill="#474747" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82 0H94V18H99.5L111.5 0H125L109.5 23.5L125.5 47.5H111.5L99.8333 30H94V48H82V0Z"
          fill="#474747"
        />
        <path
          d="M94 0H94.5V-0.5H94V0ZM82 0V-0.5H81.5V0H82ZM94 18H93.5V18.5H94V18ZM99.5 18V18.5H99.7676L99.916 18.2773L99.5 18ZM111.5 0V-0.5H111.232L111.084 -0.27735L111.5 0ZM125 0L125.417 0.275297L125.929 -0.5H125V0ZM109.5 23.5L109.083 23.2247L108.9 23.5015L109.084 23.7773L109.5 23.5ZM125.5 47.5V48H126.434L125.916 47.2226L125.5 47.5ZM111.5 47.5L111.084 47.7774L111.232 48H111.5V47.5ZM99.8333 30L100.249 29.7227L100.101 29.5H99.8333V30ZM94 30V29.5H93.5V30H94ZM94 48V48.5H94.5V48H94ZM82 48H81.5V48.5H82V48ZM94 -0.5H82V0.5H94V-0.5ZM94.5 18V0H93.5V18H94.5ZM99.5 17.5H94V18.5H99.5V17.5ZM111.084 -0.27735L99.084 17.7227L99.916 18.2773L111.916 0.27735L111.084 -0.27735ZM125 -0.5H111.5V0.5H125V-0.5ZM109.917 23.7753L125.417 0.275297L124.583 -0.275297L109.083 23.2247L109.917 23.7753ZM125.916 47.2226L109.916 23.2227L109.084 23.7773L125.084 47.7774L125.916 47.2226ZM111.5 48H125.5V47H111.5V48ZM99.4173 30.2773L111.084 47.7774L111.916 47.2226L100.249 29.7227L99.4173 30.2773ZM94 30.5H99.8333V29.5H94V30.5ZM94.5 48V30H93.5V48H94.5ZM82 48.5H94V47.5H82V48.5ZM81.5 0V48H82.5V0H81.5Z"
          fill="#474747"
        />
      </g>
      <defs>
        <clipPath id="clip0_327_47">
          <rect width="175" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
