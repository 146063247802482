import React from 'react'
import { IconProps } from '../types'

export const EtalonIcon = ({ width, height }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 176 31"
      fill="none">
      <g clipPath="url(#clip0)">
        <path
          d="M61.2676 0.0242754L49.605 30.0668H55.8022L58.0841 24.1852H66.2787L68.5433 18.3557H60.3486L64.4685 7.74731L73.1348 30.0668H79.3319L67.6659 0.0242754H61.2676ZM49.605 0.0520187H28.5895V5.85037H36.2189V30.0911H42.0172V5.85037H49.605V0.0520187ZM10.6916 0.0485508C6.5058 0.0485508 2.71884 1.76517 0 4.52909L4.10948 8.63857C5.77754 6.92542 8.11145 5.8573 10.6916 5.8573C15.7651 5.8573 19.8781 9.97025 19.8781 15.0438C19.8781 20.1174 15.7651 24.2303 10.6916 24.2303C8.11145 24.2303 5.77754 23.1657 4.10948 21.4525L0 25.562C2.71884 28.3259 6.5058 30.0425 10.6916 30.0425C18.9729 30.0425 25.6903 23.3286 25.6903 15.0473C25.6868 6.76243 18.9729 0.0485508 10.6916 0.0485508ZM19.0735 12.1308H6.92889L6.99131 17.9603H16.809L19.0735 12.1308ZM155.383 0.0242754H149.516V30.0668H155.383V17.9638H167.764L170.028 12.1342H155.383V0.0242754ZM170.132 0.0242754L170.122 13.5214V30.0633H175.997L176 0.0242754H170.132ZM128.722 0C120.441 0 113.727 6.71388 113.727 14.9987C113.727 23.2801 120.441 29.994 128.722 29.994C137.007 29.994 143.721 23.2801 143.721 14.9987C143.721 6.71388 137.007 0 128.722 0ZM128.722 24.1817C123.648 24.1817 119.535 20.0688 119.535 14.9953C119.535 9.9217 123.648 5.80875 128.722 5.80875C133.796 5.80875 137.908 9.9217 137.908 14.9953C137.908 20.0688 133.796 24.1817 128.722 24.1817ZM93.8902 0.0242754L82.2277 30.0668H88.4248L97.0911 7.74731L105.757 30.0668H111.955L100.292 0.0242754H93.8902Z"
          fill="#474747"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="176" height="30.0911" fill="#474747"></rect>
        </clipPath>
      </defs>
    </svg>
  )
}
