import { SwiperSlide } from 'swiper/react'
import {
  EtalonIcon,
  VnukovoIcon,
  IngradIcon,
  MicIcon,
  LcrIcon,
  TkpIcon,
  AcvilonIcon,
  X5Icon,
  UTCIcon,
  ILIcon,
} from '../../../../assets/icons'

import vnukovo1 from '../../../../assets/images/clients/vnukovo/vnukovo-01.jpg'
import vnukovo2 from '../../../../assets/images/clients/vnukovo/vnukovo-02.jpg'
import vnukovo3 from '../../../../assets/images/clients/vnukovo/vnukovo-03.jpg'
import vnukovo4 from '../../../../assets/images/clients/vnukovo/vnukovo-04.jpg'
import vnukovo5 from '../../../../assets/images/clients/vnukovo/vnukovo-05.jpg'
import vnukovo6 from '../../../../assets/images/clients/vnukovo/vnukovo-06.jpg'

import x5img1 from '../../../../assets/images/clients/x5/x5-01.jpg'
import x5img2 from '../../../../assets/images/clients/x5/x5-02.jpg'
import x5img3 from '../../../../assets/images/clients/x5/x5-03.jpg'
import x5img4 from '../../../../assets/images/clients/x5/x5-04.jpg'

import Utcimg1 from '../../../../assets/images/clients/UTC/Utc-01.jpg'
import Utcimg2 from '../../../../assets/images/clients/UTC/Utc-02.jpg'
import Utcimg3 from '../../../../assets/images/clients/UTC/Utc-03.jpg'
import Utcimg4 from '../../../../assets/images/clients/UTC/Utc-04.jpg'
import Utcimg5 from '../../../../assets/images/clients/UTC/Utc-05.jpg'
import Utcimg6 from '../../../../assets/images/clients/UTC/Utc-06.jpg'
import Utcimg7 from '../../../../assets/images/clients/UTC/Utc-07.jpg'
import Utcimg8 from '../../../../assets/images/clients/UTC/Utc-08.jpg'
import Utcimg9 from '../../../../assets/images/clients/UTC/Utc-09.jpg'

import ILimg1 from '../../../../assets/images/clients/IL/IL-01.jpg'
import ILimg2 from '../../../../assets/images/clients/IL/IL-02.jpg'
import ILimg3 from '../../../../assets/images/clients/IL/IL-03.jpg'
import ILimg4 from '../../../../assets/images/clients/IL/IL-04.jpg'
import ILimg5 from '../../../../assets/images/clients/IL/IL-05.jpg'
import ILimg6 from '../../../../assets/images/clients/IL/IL-06.jpg'
import ILimg7 from '../../../../assets/images/clients/IL/IL-07.jpg'
import ILimg8 from '../../../../assets/images/clients/IL/IL-08.jpg'

import businessImg1 from '../../../../assets/images/elevator-classes/business/business-01.png'
import businessImg3 from '../../../../assets/images/elevator-classes/business/business-03.png'
import businessImg4 from '../../../../assets/images/elevator-classes/business/business-04.png'
import businessImg5 from '../../../../assets/images/elevator-classes/business/business-05.png'
import businessImg6 from '../../../../assets/images/elevator-classes/business/business-06.png'
import businessImg7 from '../../../../assets/images/elevator-classes/business/business-07.png'

export const clientsMock = [
  {
    id: 1,
    img: <VnukovoIcon width={214} height={49} />,
    title: 'Внуково',
    description:
      'Монтаж лифтового и эскалаторного оборудования, строительство шахт',
    modalSlide: (
      <>
        <SwiperSlide>
          <img src={vnukovo1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={vnukovo2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={vnukovo3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={vnukovo4} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={vnukovo5} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={vnukovo6} alt="" />
        </SwiperSlide>
      </>
    ),
  },
  {
    id: 2,
    img: <X5Icon width={226} height={69} />,
    title: 'X5 Group',
    description:
      'Поставка, монтаж лифтового оборудования, строительство шахт для магазинов Пятерочка в регионах Москва и Московская область. Оборудование было изготовлено индивидуально для X5 Group с использованием корпоративных цветов Заказчика.',
    modalSlide: (
      <>
        <SwiperSlide>
          <img src={x5img1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={x5img2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={x5img3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={x5img4} alt="" />
        </SwiperSlide>
      </>
    ),
  },
  {
    id: 3,
    img: <UTCIcon width={224} height={80} />,
    title: 'ТЦ Южный',
    description:
      'Проектирование и строительство панорамных шахт, поставка и монтаж лифтового и эскалаторного оборудования',
    modalSlide: (
      <>
        <SwiperSlide>
          <img src={Utcimg1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Utcimg2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Utcimg3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Utcimg4} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Utcimg5} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Utcimg6} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Utcimg7} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Utcimg8} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Utcimg9} alt="" />
        </SwiperSlide>
      </>
    ),
  },
  {
    id: 4,
    img: <ILIcon width={224} height={80} />,
    title: 'ПАО ИЛ',
    description:
      'Поставка и монтаж лифтового оборудования с индивидуальной отделкой бизнес класса по индивидуальному дизайну  Заказчика',
    modalSlide: (
      <>
        <SwiperSlide>
          <img src={ILimg1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={ILimg2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={ILimg3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={ILimg4} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={ILimg5} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={ILimg6} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={ILimg7} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={ILimg8} alt="" />
        </SwiperSlide>
      </>
    ),
  },
  {
    id: 5,
    img: <EtalonIcon width={224} height={38} />,
    title: 'Эталон',
    description:
      'Поставка лифтового оборудования Wellmaks в ЖК Изумрудные Холмы',
    modalSlide: (
      <>
        {/* <SwiperSlide>
          <img src={x5img1} alt="" />
        </SwiperSlide> */}
      </>
    ),
  },
  {
    id: 6,
    img: <LcrIcon width={185} height={58} />,
    title: 'ЛСР',
    description: 'Монтаж лифтового оборудования OTIS в МФЖК "ЛУЧИ"',
    modalSlide: (
      <>
        {/* <SwiperSlide>
          <img src={x5img1} alt="" />
        </SwiperSlide> */}
      </>
    ),
  },
  {
    id: 7,
    img: <TkpIcon width={175} height={48} />,
    title: 'ТКП',
    description:
      'Проектирование и реконструкция шахты лифта, поставка и монтаж лифтового оборудования бизнес класса Wellmaks',
    modalSlide: (
      <>
        <SwiperSlide>
          <img src={businessImg1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={businessImg3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={businessImg4} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={businessImg5} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={businessImg6} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={businessImg7} alt="" />
        </SwiperSlide>
      </>
    ),
  },
  {
    id: 8,
    img: <MicIcon width={190} height={50} />,
    title: 'МИЦ',
    description:
      'Поставка и монтаж лифтового оборудования комфорт класса в ЖК Новое Павлино',
    modalSlide: (
      <>
        {/* <SwiperSlide>
          <img src={x5img1} alt="" />
        </SwiperSlide> */}
      </>
    ),
  },

  {
    id: 9,
    img: <AcvilonIcon width={212} height={39} />,
    title: 'Аквилон',
    description: 'Монтаж лифтового оборудования OTIS в ЖК Аквилон Парк',
    modalSlide: (
      <>
        {/* <SwiperSlide>
          <img src={x5img1} alt="" />
        </SwiperSlide> */}
      </>
    ),
  },
  {
    id: 10,
    img: <IngradIcon width={167} height={82} />,
    title: 'INGRAD',
    description:
      'Поставка и монтаж лифтового оборудования бизнес класса Wellmaks (объект пока не пишем будет ЖК Пушкино, но напишем попозже)',
    modalSlide: (
      <>
        {/* <SwiperSlide>
          <img src={x5img1} alt="" />
        </SwiperSlide> */}
      </>
    ),
  },
]
