export const footerMock = [
  {
    id: 1,
    name: 'Premium',
    url: '/premium',
  },
  {
    id: 2,
    name: 'Business',
    url: '/business',
  },
  {
    id: 3,
    name: 'Comfort',
    url: '/comfort',
  },
  {
    id: 4,
    name: 'SBL Design',
    url: '/sbl-design',
  },
  {
    id: 5,
    name: 'О компании',
    url: '/about',
  },
  {
    id: 6,
    name: 'Контакты',
    url: '/',
  },
]

export const footerMobileMock = [
  {
    id: 1,
    name: 'Premium',
    url: '/premium',
  },
  {
    id: 2,
    name: 'SBL Design',
    url: '/sbl-design',
  },
  {
    id: 3,
    name: 'Business',
    url: '/business',
  },
  {
    id: 4,
    name: 'О компании',
    url: '/about',
  },
  {
    id: 5,
    name: 'Comfort',
    url: '/comfort',
  },
  {
    id: 6,
    name: 'Контакты',
    url: '/',
  },
]
