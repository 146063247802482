import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hook/app'
import useWindowSize from '../../hook/useWindowSize'
import {
  setScrollPage,
  setShowModal /*, setThxModal */,
} from '../../store/appSlice'
import styles from './styles.module.scss'
import { ThankYou } from './ThankYou'
import { ValidationSchema } from './ValidationSchema'
import { ValidationSchemaMob } from './ValidationSchemaMob'

export const ModalForm = () => {
  const [width] = useWindowSize()
  const thxModal = useAppSelector(state => state.app.thxModal)
  const modal = useAppSelector(state => state.app.modal)

  const dispatch = useAppDispatch()

  const closeModalHandler = () => {
    dispatch(setShowModal(false))
    dispatch(setScrollPage(true))
  }

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        dispatch(setShowModal(false))
        dispatch(setScrollPage(true))
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  })

  return (
    <>
      {modal ? (
        <div onClick={closeModalHandler} className={styles.back_drop} />
      ) : null}
      {thxModal ? (
        <ThankYou />
      ) : (
        <div
          className={styles.modal_wrapper}
          style={{
            display: modal ? 'block' : 'none',
          }}>
          <div className={styles.modal_content}>
            <span
              onClick={closeModalHandler}
              className={styles.close_modal_btn}
            />
            <div className={styles.modal_body}>
              <h4 className={styles.modal_title}>Заявка на оказание услуг</h4>
              <div className={styles.top_input}>
                {width > 768 ? <ValidationSchema /> : <ValidationSchemaMob />}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
