import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'

import { Logo } from '../../../assets/icons'
import styles from './styles.module.scss'

export const HeaderDesktop: React.FC = () => {
  const location = useLocation()

  const scrollClassesElevator = () => {
    if (location.pathname === '/') {
      document.querySelector('#elevator-classes')?.scrollIntoView({
        behavior: 'smooth',
      })
    } else {
      setTimeout(() => {
        document.querySelector('#elevator-classes')?.scrollIntoView({
          behavior: 'smooth',
        })
      }, 3100)
      location.pathname = '/'
    }
  }
  const scrollToContacts = () => {
    if (location.pathname === '/') {
      document.querySelector('#contacts')?.scrollIntoView({
        behavior: 'smooth',
      })
    } else {
      setTimeout(() => {
        document.querySelector('#contacts')?.scrollIntoView({
          behavior: 'smooth',
        })
      }, 3100)
      location.pathname = '/'
    }
  }

  return (
    <header className={styles.header}>
      <NavLink to="/">
        <Logo width={90} height={65} />
      </NavLink>
      <div className={styles.header_nav}>
        <NavLink
          to="/"
          className={styles.nav_link}
          onClick={scrollClassesElevator}>
          Классы лифтов
        </NavLink>
        <Link to="/sbl-design" className={styles.nav_link}>
          SBL Design
        </Link>
        <Link to="/about" className={styles.nav_link}>
          О компании
        </Link>
        <NavLink to="/" className={styles.nav_link} onClick={scrollToContacts}>
          Контакты
        </NavLink>
      </div>
    </header>
  )
}
