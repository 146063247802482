import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Footer, Header } from './components'

import {
  Main,
  About,
  Premium,
  Comfort,
  Business,
  SBLDesign,
  PrivacyPolicy,
} from './pages'

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="about" element={<About />} />
        <Route path="/premium" element={<Premium />} />
        <Route path="/business" element={<Business />} />
        <Route path="/comfort" element={<Comfort />} />
        <Route path="/sbl-design" element={<SBLDesign />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App
