import sblDesignImg from '../../assets/images/presentation-elevators/elevator-01.png'
import sblDesignImg2 from '../../assets/images/presentation-elevators/elevator-02.png'

export const SBLDesignMock = [
  {
    id: 1,
    title: 'О ателье:',
    src: sblDesignImg,
    description: (
      <>
        <p>
          За свою долгую историю мы создали комплексную компанию, работающую в
          сфере монтажа и эксплуатации лифтового оборудования, а также имеющую
          компетенции в выполнии проектных и строительных работ повышенной
          сложности.
        </p>
        <p>
          Одним из конкурентных преимуществ компании является выполнение
          приоритетных проектов Заказчика в сложных условиях, сжатых сроках и
          без потерь качества.
        </p>
      </>
    ),
    transform: 0,
  },
  {
    id: 2,
    title: 'О компании:',
    src: sblDesignImg2,
    description: (
      <>
        <p>
          За свою долгую историю мы создали комплексную компанию, работающую в
          сфере монтажа и эксплуатации лифтового оборудования, а также имеющую
          компетенции в выполнии проектных и строительных работ повышенной
          сложности.
        </p>
        <p>
          Одним из конкурентных преимуществ компании является выполнение
          приоритетных проектов Заказчика в сложных условиях, сжатых сроках и
          без потерь качества.
        </p>
      </>
    ),
    transform: -16,
  },
  // {
  //   id: 3,
  //   title: 'О ателье:',
  //   src: sblDesignImg3,
  //   description: (
  //     <>
  //       <p>
  //         За свою долгую историю мы создали комплексную компанию, работающую в
  //         сфере монтажа и эксплуатации лифтового оборудования, а также имеющую
  //         компетенции в выполнии проектных и строительных работ повышенной
  //         сложности.
  //       </p>
  //       <p>
  //         Одним из конкурентных преимуществ компании является выполнение
  //         приоритетных проектов Заказчика в сложных условиях, сжатых сроках и
  //         без потерь качества.
  //       </p>
  //     </>
  //   ),
  //   transform: -32,
  // },
  // {
  //   id: 4,
  //   title: 'О компании:',
  //   src: sblDesignImg4,
  //   description: (
  //     <>
  //       <p>
  //         За свою долгую историю мы создали комплексную компанию, работающую в
  //         сфере монтажа и эксплуатации лифтового оборудования, а также имеющую
  //         компетенции в выполнии проектных и строительных работ повышенной
  //         сложности.
  //       </p>
  //       <p>
  //         Одним из конкурентных преимуществ компании является выполнение
  //         приоритетных проектов Заказчика в сложных условиях, сжатых сроках и
  //         без потерь качества.
  //       </p>
  //     </>
  //   ),
  //   transform: -48,
  // },
]
