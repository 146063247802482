import React from "react";
import { FieldsProps } from "../types";

export const Description = ({ children, className, color }: FieldsProps) => (
  <span
    className={
      className
        ? `description ${className || ""}`
        : `description ${className || ""} description_${color}`
    }
  >
    {children}
  </span>
);
