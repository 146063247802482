import React, { FC } from 'react'

import { TitleH2 } from '../../../../fields'
import { clientsMock } from './clientsMock'
import { ClientsTypes } from './types'
import { Client } from './Client'
import styles from './styles.module.scss'

export const Clients: FC = () => {
  return (
    <div className={styles.clients_wrap}>
      <TitleH2 color="black">Наши клиенты</TitleH2>
      <div className={styles.clients}>
        {clientsMock.map((item: ClientsTypes) => (
          <Client
            key={item.id}
            title={item.title}
            modalSlide={item.modalSlide}
            description={item.description}
            img={item.img}
          />
        ))}
      </div>
    </div>
  )
}
