import React, { FC } from 'react'
import { TitleH2 } from '../../fields'
import { Swiper } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper'
import { ModalClientsProps } from './types'
import styles from './styles.module.scss'

export const ModalClients: FC<ModalClientsProps> = ({
  title,
  description,
  style,
  onClick,
  modalSlide,
}) => {
  return (
    <>
      <div className={styles.back_drop} onClick={onClick} />
      <div
        style={{
          display: style,
        }}
        className={styles.modal}>
        <span onClick={onClick} className={styles.close_modal_btn} />
        <TitleH2 color="white">{title}</TitleH2>
        <p className={styles.modal_description}>{description}</p>

        <Swiper
          navigation={true}
          loop={true}
          modules={[Navigation]}
          className={styles.mySwiper}>
          {modalSlide}
        </Swiper>
      </div>
    </>
  )
}
