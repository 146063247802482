import React, { useState, useEffect } from 'react'

import styles from './styles.module.scss'

export const Loader = ({ show, close }: any) => {
  const [isLoader, setLoader] = useState(true)

  useEffect(() => {
    // setTimeout(() => setLoader(false), 3000)
    setTimeout(() => setLoader(false), 0)
    if (isLoader && show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
  })
  return (
    <div
      className={styles.loader_wrap}
      style={isLoader ? { display: 'block' } : { display: 'none' }}>
      <div className={styles.loader}>
        <span className={styles.loader_block} />
        <span className={styles.loader_block} />
        <span className={styles.loader_block} />
        <span className={styles.loader_block} />
        <span className={styles.loader_block} />
        <span className={styles.loader_block} />
        <span className={styles.loader_block} />
        <span className={styles.loader_block} />
        <span className={styles.loader_block} />
      </div>
    </div>
  )
}
