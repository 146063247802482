import React from "react";
import { IconProps } from "../types";

export const AcvilonIcon = ({ width, height }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 212 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_612_32)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.1158 37.7682L20.7044 10.1504L20.6806 10.0972C19.9314 8.43154 18.2316 7.26953 16.2543 7.26953H13.8815C13.7837 7.26953 13.6953 7.32628 13.6561 7.41426L0.0152154 37.7682C0.00507178 37.7887 0 37.8121 0 37.8377C0 37.932 0.078975 38.0093 0.176063 38.0093H5.85792C5.95572 38.0093 6.0434 37.9532 6.08324 37.866L9.18428 31.0004H20.8574C20.8574 28.3857 18.6924 26.2658 16.0218 26.2658H11.3014L16.5529 14.0358L22.2926 27.3484L27.0224 37.8653C27.0624 37.9532 27.1508 38.0093 27.2486 38.0093H32.955C33.0522 38.0093 33.131 37.932 33.131 37.8377C33.131 37.8121 33.1252 37.7887 33.1158 37.7682Z"
          fill="#474747"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.3474 25.772C81.4096 24.896 82.876 23.387 82.876 20.3934C82.876 16.791 79.7452 14.5273 74.9748 14.5273H62.9228C62.7866 14.5273 62.6758 14.6359 62.6758 14.7693V37.773C62.6758 37.9064 62.7866 38.0143 62.9228 38.0143H75.1242C80.9624 38.0143 83.9694 35.6045 83.9694 31.6623C83.9694 28.6196 82.2544 26.5751 79.3474 25.772ZM68.167 18.7873H74.0554C76.1674 18.7873 77.2854 19.882 77.2854 21.3184C77.2854 23.0458 76.142 24.044 74.0554 24.044H68.167V18.7873ZM74.751 33.755H68.167V27.8896H74.751C77.0868 27.8896 78.3788 28.936 78.3788 30.8585C78.3788 32.4653 77.1362 33.755 74.751 33.755Z"
          fill="#474747"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.988 14.0352C149.758 14.0352 144.168 19.3159 144.168 26.3015C144.168 33.311 149.759 38.5925 156.988 38.5925C164.218 38.5925 169.808 33.311 169.808 26.3015C169.808 19.3159 164.218 14.0352 156.988 14.0352ZM156.988 33.8707C152.591 33.8707 149.683 30.6578 149.683 26.3015C149.683 21.9691 152.591 18.7321 156.988 18.7321C161.385 18.7321 164.292 21.9691 164.292 26.3015C164.292 30.6578 161.385 33.8707 156.988 33.8707Z"
          fill="#474747"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M177.878 14.5273H175.075C174.939 14.5273 174.828 14.6352 174.828 14.7685V37.773C174.828 37.9064 174.938 38.0136 175.075 38.0136H180.098C180.234 38.0136 180.345 37.9064 180.345 37.773V16.9414C180.344 15.6078 179.24 14.5273 177.878 14.5273Z"
          fill="#474747"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M194.697 14.5273H191.919C191.783 14.5273 191.672 14.6352 191.672 14.7685V24.0546H182.496C182.496 26.5354 184.55 28.5465 187.084 28.5465H191.672V37.773C191.672 37.9064 191.783 38.0136 191.919 38.0136H196.917C197.053 38.0136 197.163 37.9064 197.163 37.773V16.9414C197.163 15.6078 196.059 14.5273 194.697 14.5273Z"
          fill="#474747"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M136.681 14.5273H124.557C124.53 14.5273 124.502 14.5281 124.476 14.5295C123.425 14.5621 122.541 15.2382 122.22 16.1682L122.219 16.1689L122.208 16.2037C122.204 16.215 122.201 16.2271 122.196 16.2384L115.22 37.7901C115.214 37.805 115.211 37.8241 115.211 37.8418C115.211 37.9368 115.291 38.0129 115.387 38.0129H119.104C120.18 38.0129 121.131 37.3311 121.455 36.3265L126.998 19.152H133.656V37.773C133.656 37.9064 133.767 38.0136 133.903 38.0136H138.9C139.036 38.0136 139.147 37.9064 139.147 37.773V16.9421C139.148 15.6078 138.043 14.5273 136.681 14.5273Z"
          fill="#474747"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.051 14.5281H106.295C106.176 14.5281 106.064 14.5834 105.996 14.6777L94.7021 29.9099V16.9421C94.7021 15.6085 93.5979 14.5273 92.2351 14.5273H89.4571C89.3209 14.5273 89.2109 14.6352 89.2109 14.7685V37.773C89.2109 37.9064 89.3209 38.0136 89.4571 38.0136H94.1907C94.3087 38.0136 94.4195 37.9588 94.4899 37.8645L105.783 22.6322V37.773C105.783 37.9064 105.893 38.0136 106.03 38.0136H111.052C111.188 38.0136 111.299 37.9064 111.299 37.773V14.7685C111.297 14.6352 111.188 14.5281 111.051 14.5281Z"
          fill="#474747"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.514 25.682L57.85 14.7941C57.868 14.7671 57.8788 14.7345 57.8788 14.6997C57.8788 14.6047 57.8 14.5273 57.7028 14.5273H52.15C52.0666 14.5273 51.9898 14.5678 51.9442 14.6359L45.6654 23.9468H41.9796V16.9421C41.9796 15.6085 40.8754 14.5273 39.5132 14.5273H36.7346C36.5992 14.5273 36.4882 14.6359 36.4882 14.7693V37.773C36.4882 37.9056 36.5992 38.0143 36.7346 38.0143H41.7326C41.8688 38.0143 41.9796 37.9056 41.9796 37.773V28.2301H46.208L52.6152 37.9042C52.66 37.9737 52.7382 38.0143 52.8224 38.0143H58.3266H58.3282C58.4252 38.0143 58.5034 37.9376 58.5034 37.8425C58.5034 37.8071 58.4926 37.7745 58.4744 37.7475L50.514 25.682Z"
          fill="#474747"
        />
        <path
          d="M197.164 7.1153C201.178 7.1153 204.432 3.92937 204.432 0H204.732C204.732 3.92937 207.988 7.1153 212.002 7.1153V7.41041C207.988 7.41041 204.732 10.5956 204.732 14.5257H204.432C204.432 10.5956 201.178 7.41041 197.164 7.41041V7.1153Z"
          fill="#474747"
        />
      </g>
      <defs>
        <clipPath id="clip0_612_32">
          <rect width="212" height="39" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
