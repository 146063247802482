import React from "react";
import { IconProps } from "../types";

export const InstagramIcon = ({ width, height }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_155_1568)">
        <path
          d="M23.6719 12.0452C23.6719 13.6764 23.6391 15.3124 23.6812 16.9436C23.7516 19.8311 21.9375 22.0624 19.6266 23.0561C18.6984 23.4546 17.7234 23.6655 16.7156 23.6655C13.5609 23.6702 10.4016 23.7124 7.24687 23.6514C4.87031 23.6046 2.91562 22.6108 1.51406 20.6514C0.778122 19.6202 0.426559 18.4483 0.426559 17.178C0.426559 13.8171 0.426559 10.4608 0.426559 7.09987C0.426559 4.35769 1.7625 2.4405 4.13437 1.19362C5.16562 0.65456 6.28593 0.424872 7.45312 0.420185C10.5141 0.420185 13.5797 0.401435 16.6406 0.42956C19.0172 0.44831 21 1.35769 22.4625 3.27018C23.2781 4.33893 23.6719 5.56706 23.6719 6.91706C23.6719 8.62331 23.6719 10.3342 23.6719 12.0452ZM2.15625 12.0452C2.15625 13.7655 2.15625 15.4811 2.15625 17.2014C2.15625 17.9702 2.33437 18.6921 2.73281 19.3436C3.77812 21.0546 5.35781 21.8889 7.31718 21.9217C10.4625 21.9686 13.6125 21.9405 16.7625 21.9311C17.5359 21.9311 18.2859 21.7717 18.9844 21.4389C20.8875 20.5296 21.9516 19.0811 21.9422 16.9061C21.9234 13.578 21.9375 10.2499 21.9375 6.92175C21.9375 5.97956 21.6703 5.11706 21.1125 4.35769C20.0344 2.89987 18.5437 2.18268 16.7625 2.16393C13.6219 2.12643 10.4812 2.14987 7.34062 2.15456C6.67031 2.15456 6.02343 2.2905 5.39531 2.52487C3.67031 3.16706 2.06718 4.79362 2.14218 7.18893C2.19843 8.81081 2.15625 10.428 2.15625 12.0452Z"
          fill="#EEEEEE"
        />
        <path
          d="M12.075 17.0156C9.05155 17.0156 6.61405 14.6063 6.60937 11.6063C6.60468 8.65313 9.06093 6.23438 12.0656 6.23438C15.0797 6.22969 17.5266 8.64376 17.5312 11.6203C17.5312 14.5922 15.0891 17.0109 12.075 17.0156ZM12.075 15.2813C14.1328 15.2813 15.8016 13.6359 15.7969 11.6109C15.7922 9.60469 14.1234 7.96407 12.075 7.96407C10.0172 7.96407 8.33905 9.60001 8.33905 11.6203C8.34374 13.6453 10.0172 15.2859 12.075 15.2813Z"
          fill="#EEEEEE"
        />
        <path
          d="M18.1641 4.59375C18.7969 4.59375 19.3125 5.13281 19.3125 5.79375C19.3078 6.45469 18.7875 6.98906 18.1547 6.98438C17.5312 6.97969 17.0203 6.44531 17.0203 5.79844C17.0156 5.1375 17.5312 4.59375 18.1641 4.59375Z"
          fill="#EEEEEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_155_1568">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
