import React from 'react'

import { ButtonModal, TitleH2 } from '../../../../fields'
import { ModalForm } from '../../../../components'
import { contactsMock } from './contactsMock'
import styles from './styles.module.scss'

export const Contacts = () => {
  return (
    <div className={styles.contact_block} id="contacts">
      <TitleH2 color="black">Контакты</TitleH2>
      <div className={styles.contacts_wrap}>
        <div className={styles.contacts}>
          {contactsMock.map((item: any) => (
            <div className={styles.contact} key={item.id}>
              <span className={styles.contact_title}>{item.title}</span>
              {item.contact}
            </div>
          ))}
        </div>
        <div className={styles.contacts_text}>
          <p>
            Бюро Специальных Проектов это всегда индивидуальный подход, высокое
            качество и реализация Ваших самых грандиозных задач.
          </p>
          <ButtonModal color="white" />
          <ModalForm />
        </div>
      </div>
    </div>
  )
}
