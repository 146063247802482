import React from 'react'
import { Link } from 'react-router-dom'
import { FieldsProps } from '../types'

export const Button = ({
  children,
  to,
  color = 'black',
  className,
  onClick,
}: FieldsProps) => (
  <Link
    preventScrollReset={true}
    to={to}
    className={className ? 'button' : `button_${color}`}
    onClick={onClick}>
    <span className="button_circle_holder">
      <span className="button_circle" />
    </span>
    <span className="button__text">{children}</span>
  </Link>
)
