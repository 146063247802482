import React from "react";
import { IconProps } from "../types";

export const MicIcon = ({ width, height }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 190 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_155_1406"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="190"
        height="50"
      >
        <g clipPath="url(#clip0_155_1406)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M181.553 45.1084C183.215 45.1175 184.842 44.6325 186.228 43.7145C187.615 42.7965 188.699 41.4868 189.343 39.9511C189.987 38.4154 190.163 36.7227 189.848 35.0869C189.532 33.4512 188.74 31.946 187.572 30.7615C186.403 29.5771 184.911 28.7666 183.283 28.4327C181.655 28.0987 179.965 28.2563 178.427 28.8854C176.888 29.5145 175.57 30.587 174.639 31.9671C173.709 33.3472 173.207 34.9731 173.198 36.6391C173.218 38.8632 174.102 40.9918 175.662 42.5731C177.222 44.1544 179.335 45.0639 181.553 45.1084Z"
            fill="#C82B09"
          />
          <path
            d="M44.9336 45.1091V19.976L31.5307 40.1371H23.1742L9.95269 19.976V45.1091H-0.0524902V0H11.2386L27.5824 28.2606L44.7522 0H54.7574L54.942 45.1091H44.9336Z"
            fill="#141515"
          />
          <path
            d="M64.8568 0H74.8652V30.102L99.9316 0H109.755V45.1091H99.9316V15.0039L74.8652 45.1091H64.8568V0Z"
            fill="#141515"
          />
          <path
            d="M159.7 45.1091H119.854V0H129.863V34.9831H149.786V0H159.7V45.1091Z"
            fill="#141515"
          />
          <path
            d="M164.751 34.9844H154.744V49.9898H164.751V34.9844Z"
            fill="#141515"
          />
        </g>
      </mask>
      <g mask="url(#mask0_155_1406)">
        <rect
          x="-5.64362"
          y="-7.54688"
          width="212.574"
          height="65.0943"
          fill="#474747"
        />
      </g>
      <defs>
        <clipPath id="clip0_155_1406">
          <rect width="190" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
