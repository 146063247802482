import React from "react";
import { FieldsProps } from "../types";

export const ButtonPhone = ({ phone, label, className }: FieldsProps) => {
  return (
    <a href={`tel:${phone}`} className={className}>
      {label}
    </a>
  );
};
