import React, { useState } from 'react'

import { Container, Loader } from '../../components'
// import { Test } from '../../components/ModalForm/Test'
import {
  Presentation,
  About,
  ElevatorClasses,
  SBLDesign,
  Clients,
  Contacts,
} from './components'

export const Main: React.FC = () => {
  const [isLoading] = useState(true)
  const [show, setShow] = useState(true)

  const closeLoadingHandler = () => setShow(false)

  return (
    <>
      {isLoading ? <Loader show={show} close={closeLoadingHandler} /> : null}
      <Container>
        {/* <Test /> */}
        <Presentation />
      </Container>
      <About />
      <ElevatorClasses />
      <Container>
        <SBLDesign />
        <Clients />
        <Contacts />
      </Container>
    </>
  )
}
