export const privacyPolicyMock = [
  {
    id: 1,
    title: '1. Общие положения',
    description: (
      <>
        <p>
          Настоящая политика в отношении обработки персональных данных (далее –
          Политика ПД) ООО «Бюро Специальных Проектов» (далее – Предприятие)
          является публичной и определяет порядок обработки персональных данных
          (далее – ПД), принципы и условия обработки ПД, права субъектов ПД, а
          также содержит сведения о реализуемых требованиях к защите ПД.
          Политика ПД является локальным нормативным документом 1-ого уровня (в
          соответствии с п. 5.1. Политики информационной безопасности ООО «Бюро
          Специальных Проектов») и входит в систему документов Предприятия в
          направлении обеспечения ИБ, является основой для разработки Положений,
          Правил и Инструкций последующих уровней.
        </p>
        <p>
          Нормативно-правовой базой Политики ПД выступает законодательство
          Российской федерации, требования международных и национальных
          стандартов и регламентов в сфере обеспечения безопасности
          обрабатываемых ПД, требования федерального органа исполнительной
          власти, уполномоченного по защите прав субъектов персональных данных
          (Роскомнадзор).
        </p>
        <p>
          Политика ПД является общедоступным документом и закладывает основы
          деятельности Предприятия при обработке и защите ПД. Данная политика
          призвана обеспечить обрабатываемым на Предприятии ПД надлежащую защиту
          и максимально исключить вероятность нарушения законных прав и
          интересов субъектов.
        </p>
        <p>
          Требования, изложенные в настоящей Политике ПД, должны неукоснительно
          соблюдаться работниками и руководством Предприятия, а также должны
          быть учтены при заключении договоров и соглашений, стороной которых
          является Предприятие.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: '2. Принципы и цели обработки персональных данных',
    description: (
      <>
        <p>
          2.1. Предприятие осуществляет обработку ПД работников Предприятия и
          других субъектов ПД, не состоящих с Предприятием в трудовых
          отношениях.
        </p>
        <p>2.2. Обработка ПД осуществляется на основе следующих принципов:</p>
        <p>2.2.1. законной и справедливой основы;</p>
        <p>
          2.2.2. ограничения обработки ПД достижением конкретных, заранее
          определённых и законных целей;
        </p>
        <p>2.2.3. недопущения обработки ПД, несовместимой с целями сбора ПД;</p>
        <p>
          2.2.4. недопущения объединения баз данных, содержащих ПД, обработка
          которых осуществляется в целях, несовместимых между собой;
        </p>
        <p>
          2.2.5. обработки только тех ПД, которые отвечают целям их обработки;
        </p>
        <p>
          2.2.6. соответствия содержания и объёма обрабатываемых ПД заявленным
          целям обработки;
        </p>
        <p>
          2.2.7. недопущения обработки избыточных ПД по отношению к заявленным
          целям их обработки;
        </p>
        <p>
          2.2.8. обеспечения точности, достаточности и актуальности ПД по
          отношению к целям обработки ПД;
        </p>
        <p>
          2.2.9. уничтожения либо обезличивания ПД по достижении целей их
          обработки или в случае утраты необходимости в достижении этих целей,
          если иное не предусмотрено федеральным законом;
        </p>
        <p>
          2.2.10. принятие необходимые мер и (или) обеспечение их принятия по
          удалению или уточнению неполных, или неточных данных.
        </p>
        <p>2.3. Персональные данные обрабатываются в целях:</p>
        <p>
          2.3.1. обеспечения соблюдения Конституции Российской Федерации,
          законодательных и иных нормативных правовых актов Российской
          Федерации, локальных нормативных актов Предприятия;
        </p>
        <p>
          2.3.2. регулирования трудовых отношений с работниками Предприятия
          (содействие в трудоустройстве, обучение и продвижение по службе,
          обеспечение личной безопасности, контроль количества и качества
          выполняемой работы, обеспечение сохранности имущества);
        </p>
        <p>
          2.3.3. предотвращения нарушений законных прав и интересов субъектов
          при обращении их ПД на Предприятии;
        </p>
        <p>
          2.3.4. защиты жизни, здоровья или иных жизненно важных интересов
          субъектов ПД;
        </p>
        <p>
          2.3.5. осуществления прав и законных интересов Предприятия в рамках
          осуществления видов деятельности, предусмотренных Уставом и иными
          локальными нормативными актами, или третьих лиц,;
        </p>
        <p>2.3.6. в иных законных целях.</p>
        <p>
          2.4. Обработка персональных данных на Предприятии осуществляется
          следующими способами:
        </p>
        <p>2.4.1. неавтоматизированная обработка ПД;</p>
        <p>
          2.4.2. автоматизированная обработка ПД с передачей полученной
          информации по информационно-телекоммуникационным сетям или без
          таковой;
        </p>
        <p>2.4.3. смешанная обработка ПД.</p>
      </>
    ),
  },
  {
    id: 3,
    title: '3. Права субъектов персональных данных',
    description: (
      <>
        <p>
          3.1. Субъект ПД имеет право на получение полных сведений об обработке
          его персональных данных на Предприятии.
        </p>
        <p>
          3.2. Субъект персональных данных вправе требовать от Предприятия
          уточнения его ПД, их блокирования или уничтожения в случае, если они
          являются неполными, устаревшими, неточными, незаконно полученными или
          не могут быть признаны необходимыми для заявленной цели обработки, а
          также принимать предусмотренные законом меры по защите своих прав.
        </p>
        <p>
          3.3. Право субъекта ПД на доступ к его ПД может быть ограничено в
          соответствии с федеральными законами, в том числе, если доступ
          субъекта персональных данных к его ПД нарушает права и законные
          интересы третьих лиц.
        </p>
        <p>
          3.4. Субъект ПД вправе обжаловать действия или бездействие Предприятия
          путем обращения в уполномоченный орган по защите прав субъектов ПД.
        </p>
        <p>
          3.5. Субъект ПД имеет право на защиту своих прав и законных интересов,
          в том числе на возмещение убытков и/или компенсацию морального вреда в
          судебном порядке.
        </p>
      </>
    ),
  },
  {
    id: 4,
    title: '4. Меры по обеспечению безопасности персональных данных',
    description: (
      <>
        <p>
          4.1. Предприятие при обработке ПД принимает все необходимые правовые,
          организационные и технические меры для их защиты от неправомерного или
          случайного доступа, уничтожения, изменения, блокирования, копирования,
          предоставления, распространения, а также от иных неправомерных
          действий в отношении них. К таким мерам обеспечения безопасности можно
          отнести:
        </p>
        <p>
          4.1.1. Определение перечня информации, составляющей ПД субъектов,
          обрабатываемых на Предприятии.
        </p>
        <p>
          4.1.2. Назначение ответственного лица за организацию обработки и
          обеспечение безопасности ПД.
        </p>
        <p>
          4.1.3. Определение угроз безопасности персональных данных при их
          обработке в информационных системах ПД.
        </p>
        <p>
          4.1.4. Принятие локальных нормативных актов и иных документов в
          области обработки и защиты ПД.
        </p>
        <p>
          4.1.5. Применение организационных и технических мер по обеспечению
          безопасности ПД при их обработке в информационных системах
          персональных данных, необходимых для выполнения требований к защите
          ПД.
        </p>
        <p>
          4.1.6. Использование программных и аппаратных средств защиты
          информации.
        </p>
        <p>
          4.1.7. Выявление фактов несанкционированного доступа к ПД и принятие
          соответствующих мер.
        </p>
        <p>
          4.1.8. Получение согласий субъектов ПД на обработку их ПД, за
          исключением случаев, предусмотренных законодательством Российской
          Федерации.
        </p>
        <p>
          4.1.9. Другие меры, которые не противоречат законодательству
          Российской Федерации.
        </p>
      </>
    ),
  },
  {
    id: 5,
    title: '5. Порядок пересмотра и внесения изменений в Политику ПД',
    description: (
      <>
        <p>
          5.1. Настоящая Политика ПД подлежит изменению, дополнению в случае
          принятия новых законодательных актов и специальных нормативных
          документов по обработке и защите ПД.
        </p>
        <p>
          5.2. Ответственность работников Предприятия, имеющих доступ к
          персональным данным, за невыполнение требований норм, регулирующих
          обработку и защиту ПД, определяется в соответствии с законодательством
          Российский Федерации.
        </p>
      </>
    ),
  },
]
