import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hook/app'
import { setScrollPage, setShowModal, setThxModal } from '../../store/appSlice'
import styles from './styles.module.scss'

export const ThankYou = () => {
  const thxModal = useAppSelector(state => state.app.thxModal)
  const dispatch = useAppDispatch()

  const closeModalHandler = () => {
    dispatch(setThxModal(false))
    dispatch(setScrollPage(true))
  }

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        dispatch(setShowModal(false))
        dispatch(setScrollPage(true))
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  })

  return (
    <>
      {thxModal ? (
        <div onClick={closeModalHandler} className={styles.back_drop} />
      ) : null}
      <div
        className={styles.modal_wrapper}
        style={{
          display: thxModal ? 'block' : 'none',
        }}>
        <div className={styles.modal_content}>
          <span
            onClick={closeModalHandler}
            className={styles.close_modal_btn}
          />
          <div
            className={styles.modal_body}
            style={{ width: '80vw', textAlign: 'center' }}>
            <h2 className={styles.thx}>
              Мы получили ваш запрос и скоро свяжемся с вами
            </h2>
            <div className="button_white" onClick={closeModalHandler}>
              <span className="button_circle_holder">
                <span className="button_circle" />
              </span>
              <span className="button__text">Закрыть</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
