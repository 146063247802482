import React from 'react'
import { NavLink } from 'react-router-dom'

import { Container } from '../Container/Container'
import { InstagramIcon, TelegramIcon } from '../../assets/icons'
import { ButtonMailTo, ButtonPhone } from '../../fields'
import useWindowSize from '../../hook/useWindowSize'
import { FooterTypes } from './types'
import { footerMock, footerMobileMock } from './footerMock'
import styles from './styles.module.scss'

export const Footer = () => {
  const [width] = useWindowSize()

  return (
    <div className={styles.footer}>
      <Container>
        <div className={styles.footer_top}>
          <div className={styles.footer_nav}>
            {width > 1665
              ? footerMock.map((item: FooterTypes) => (
                  <NavLink
                    to={item.url}
                    className={styles.footer_link}
                    key={item.id}>
                    {item.name}
                  </NavLink>
                ))
              : footerMobileMock.map((item: FooterTypes) => (
                  <NavLink
                    to={item.url}
                    className={styles.footer_link}
                    key={item.id}>
                    {item.name}
                  </NavLink>
                ))}
          </div>
          <div className={styles.footer_contacts}>
            <ButtonPhone
              phone={+74951509091}
              label=" +7 (495) 150-90-91"
              className={styles.footer_contact}
            />
            <ButtonMailTo
              label="info@bsp-lift.ru"
              mailto="mailto:info@bsp-lift.ru"
              className={styles.footer_contact}
            />
            <span className={styles.footer_contact}>
              Москва, Варшавское шоссе 148, БЦ “РТС”
            </span>
          </div>
        </div>
        <div className={styles.footer_bottom}>
          <span>© 2022 Бюро Специальных Проектов</span>
          <div className={styles.social}>
            <a
              href="https://t.me/bsplift"
              target="_blank"
              rel="noreferrer"
              className={styles.social_link}>
              <TelegramIcon width={24} height={24} />
            </a>
            <a
              href="https://instagram.com/bsplift?igshid=1n2zllfj9nyct"
              target="_blank"
              rel="noreferrer"
              className={styles.social_link}>
              <InstagramIcon width={24} height={24} />
            </a>
          </div>
        </div>
      </Container>
    </div>
  )
}
