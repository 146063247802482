import React, { FC, useState } from 'react'
import { ModalClients } from '../../../../components'
import { useAppDispatch, useAppSelector } from '../../../../hook/app'
import { setScrollPage, setShowClientsModal } from '../../../../store/appSlice'
import styles from './styles.module.scss'
import { ClientsTypes } from './types'
export const Client: FC<ClientsTypes> = ({
  img,
  title,
  description,
  modalSlide,
}) => {
  const [showModal, setShowModal] = useState(false)
  const app = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()

  const handleClick = () => {
    setShowModal(!showModal)
    dispatch(setScrollPage(!app.scrollPage))
    dispatch(setShowClientsModal(!app.clientsModal))
  }

  return (
    <div className={styles.client}>
      <div className={styles.client_logo}>{img}</div>
      <span className={styles.client_description}>{description}</span>
      <div className={styles.client_btn} onClick={handleClick}>
        Смотреть проект
      </div>
      {showModal ? (
        <ModalClients
          title={title}
          description={description}
          onClick={handleClick}
          modalSlide={modalSlide}
          style={showModal ? 'block' : 'none'}
        />
      ) : null}
    </div>
  )
}
