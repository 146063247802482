import { ButtonMailTo, ButtonPhone } from '../../../../fields'

import styles from './styles.module.scss'

export const contactsMock = [
  {
    id: 1,
    title: 'Адрес',
    contact: (
      <span
        className={`${styles.contact_description} ${styles.contact_description_address}`}>
        Москва, <br /> Варшавское шоссе 148, <br /> бизнес центр РТС
      </span>
    ),
  },
  {
    id: 2,
    title: 'Телефон',
    contact: (
      <ButtonPhone
        phone={+74951509091}
        label={`+7 (495) 150 90 91`}
        className={styles.contact_description}
      />
    ),
  },
  {
    id: 3,
    title: 'Почта',
    contact: (
      <ButtonMailTo
        label="info@bsp-lift.ru"
        mailto="mailto:info@bsp-lift.ru"
        className={styles.contact_description}
      />
    ),
  },
  {
    id: 4,
    title: 'Телеграм',
    contact: (
      <a
        href="https://t.me/bsplift"
        target="_blank"
        rel="noreferrer"
        className={styles.contact_description}>
        @bsplift
      </a>
    ),
  },
  {
    id: 5,
    title: 'Инстаграм',
    contact: (
      <a
        href="https://instagram.com/bsplift?igshid=1n2zllfj9nyct"
        target="_blank"
        rel="noreferrer"
        className={styles.contact_description}>
        @bsplift
      </a>
    ),
  },
]
