import React, { useState } from 'react'
import { ScrollTrigger, Tween } from 'react-gsap'
import { Loading } from '../../../../assets/icons'

import { TitleH2, Description, Button } from '../../../../fields'
import video from '../../../../assets/video/video.mp4'

import styles from './styles.module.scss'
import { Container } from '../../../../components'

export const About = () => {
  const [isLoading, setLoading] = useState(true)
  const videoRef = React.useRef() as React.MutableRefObject<HTMLVideoElement>

  return (
    <>
      <Container>
        <div className={styles.presentation}>
          <TitleH2 color="black">О компании</TitleH2>
          <div className={styles.presentation_text}>
            <span className={styles.presentation_subtitle}>
              Выполяем проекты в сложных условиях, сжатных сроках и без потери
              качества
            </span>
            <div>
              <Description className={styles.presentation_description}>
                Компания «Бюро Специальных Проектов» осуществляет полный
                комплекс работ по лифтам, проектирование и строительство шахт,
                поставка и монтаж оборудования, по выбору Заказчика
              </Description>
              <Button to="/about">Узнать больше</Button>
            </div>
          </div>
        </div>
      </Container>
      <div className={styles.presentation_video}>
        <ScrollTrigger
          start="top bottom"
          end="top top+=56px"
          trigger={styles.presentation}
          scrub={0}>
          <Tween
            trigger={styles.presentation}
            from={{
              width: '47%',
            }}
            to={{
              ease: 'liner',
              width: '100%',
            }}>
            <div className={styles.video_wrap}>
              {isLoading ? (
                <div className={styles.spinner_wrap}>
                  <Loading className={styles.spinner} />
                </div>
              ) : null}
              <video
                loop
                autoPlay
                muted
                ref={videoRef}
                className={styles.video}
                onLoadedData={() => setLoading(false)}>
                <source src={video} type={'video/mp4'} />
              </video>
            </div>
          </Tween>
        </ScrollTrigger>
      </div>
    </>
  )
}
