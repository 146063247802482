import React, { useEffect } from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import { Container } from '../../components'
import { TitleH2 } from '../../fields'
import { TextAnimate } from './components'
import sblDesignImg from '../../assets/images/sbl/sbl.png'
import useWindowSize from '../../hook/useWindowSize'
import styles from './styles.module.scss'

export const SBLDesign = () => {
  const [width] = useWindowSize()

  useEffect(() => {
    window.scroll(0, 0)

    return window.scroll(0, 0)
  })

  return (
    <Container>
      <div className={styles.sbl_wrap}>
        <TitleH2 color="black">
          SBL Design
          <a
            href="https://sbl-design.ru"
            target="_blank"
            className={styles.sblLink}
            rel="noreferrer">
            — перейти на сайт
          </a>
        </TitleH2>

        {width > 479 ? (
          <Controller>
            <Scene triggerHook="onLeave" duration={800} pin>
              {(progress: number) => {
                return (
                  <div className={styles.sbldsign_wrap}>
                    <div className={styles.sbldsign}>
                      <div className={styles.sbl_image_wrap}>
                        <img
                          src={sblDesignImg}
                          className={styles.sbldesign_img}
                          alt=""
                        />
                      </div>
                      {/* <ImageAnimate totalProgress={progress} /> */}
                      <TextAnimate totalProgress={progress} />
                    </div>
                  </div>
                )
              }}
            </Scene>
          </Controller>
        ) : (
          <>
            <div className={styles.sbl_image_wrap}>
              <img src={sblDesignImg} className={styles.sbldesign_img} alt="" />
            </div>
            <Controller>
              <Scene triggerHook="onLeave" duration={600} pin>
                {(progress: any) => {
                  return (
                    <div className={styles.sbldsign_wrap}>
                      <div className={styles.sbldsign}>
                        <>
                          {/* <ImageAnimate totalProgress={progress} /> */}
                          <TextAnimate totalProgress={progress} />
                        </>
                      </div>
                    </div>
                  )
                }}
              </Scene>
            </Controller>
          </>
        )}
      </div>
    </Container>
  )
}
