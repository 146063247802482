import React, { useState } from 'react'

import { ModalForm } from '../../../../components'
import { TitleH1, Description, ButtonModal } from '../../../../fields'
import { presentationMock } from './presentationMock'
import styles from './styles.module.scss'

export const Presentation = () => {
  const [showModal, setShowModal] = useState(false)

  const handleClick = () => {
    setShowModal(!showModal)
  }

  return (
    <div className={styles.presentation}>
      <TitleH1>
        Проектирование, строительство шахт, поставка и монтаж лифтов
      </TitleH1>
      <Description className={styles.description}>
        В лифтовом ремесле с момента, когда это еще не стало мейнстримом.
        <b style={{ display: 'block' }}>Репутация решает!</b>
      </Description>
      <ButtonModal className={styles.btn_openModal} onClick={handleClick} />
      {showModal ? <ModalForm /> : null}

      <div className={styles.presentation_elevator}>
        {presentationMock.map((item: any) => (
          <div className={styles.elevator} key={item.id}>
            <img src={item.img} className={styles.elevator_img} alt="" />
            <div className={styles.elevator_text}>{item.text}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
