import React from "react";
import { IconProps } from "../types";

export const LcrIcon = ({ width, height }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 179 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_325_56)">
        <g clipPath="url(#clip1_325_56)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.8817 23.9166L60.4245 29.2844L55.7286 34.9397L50.7742 40.9062L45.5395 47.2104L40 53.8817V53.8828H0V13.8828H40V13.8817H0L6.67129 8.34215L12.9754 3.10746L18.942 -1.84688L24.5973 -6.5428L29.9651 -11H64.8817V23.9166Z"
            fill="#474747"
          />
          <path
            d="M15.1094 40.625C14.5781 42.2917 13.9062 43.4792 13.0938 44.1875C12.2812 44.8958 11.1406 45.25 9.67188 45.25C8.96354 45.25 8.44792 45.1615 8.125 44.9844V41.125C8.26042 41.1979 8.48438 41.2344 8.79688 41.2344C9.09896 41.2344 9.36979 41.1458 9.60938 40.9688C9.84896 40.7812 10.0677 40.5052 10.2656 40.1406C10.4427 39.8073 10.6094 39.3906 10.7656 38.8906C10.9323 38.3802 11.0938 37.776 11.25 37.0781C11.3438 36.651 11.4427 36.1458 11.5469 35.5625C11.651 34.9792 11.7812 34.1927 11.9375 33.2031C12.1042 32.2031 12.3073 30.8854 12.5469 29.25C12.7865 27.6146 13.0833 25.526 13.4375 22.9844H28.3125V45H23.875V26.7969H17.5156L16.75 32.2344C16.1875 36.1302 15.6406 38.9271 15.1094 40.625Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip2_325_56)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M124.882 23.9166L120.424 29.2844L115.729 34.9397L110.774 40.9062L105.54 47.2104L100 53.8817V53.8828H60V13.8828H100V13.8817H60L66.6713 8.34215L72.9754 3.10746L78.942 -1.84688L84.5973 -6.5428L89.9651 -11H124.882V23.9166Z"
            fill="#474747"
          />
          <path
            d="M80.8594 41.6875C83.651 41.6875 85.5833 40.2917 86.6562 37.5L90.6719 39.0156C89.8073 41.1406 88.5365 42.724 86.8594 43.7656C85.1927 44.7969 83.1927 45.3125 80.8594 45.3125C78.5052 45.3125 76.5 44.8646 74.8438 43.9688C73.1875 43.0625 71.9219 41.7552 71.0469 40.0469C70.1823 38.3385 69.75 36.2865 69.75 33.8906C69.75 31.474 70.1667 29.4323 71 27.7656C71.8333 26.099 73.0625 24.8333 74.6875 23.9688C76.3125 23.0938 78.3021 22.6562 80.6562 22.6562C83.2396 22.6562 85.3438 23.1771 86.9688 24.2188C88.5938 25.25 89.7344 26.7708 90.3906 28.7812L86.3281 29.8906C85.9844 28.7865 85.3073 27.9115 84.2969 27.2656C83.2969 26.6094 82.1146 26.2812 80.75 26.2812C78.6667 26.2812 77.0833 26.9271 76 28.2188C74.9271 29.5104 74.3906 31.401 74.3906 33.8906C74.3906 35.5781 74.6354 37 75.125 38.1562C75.625 39.3125 76.3542 40.1927 77.3125 40.7969C78.2812 41.3906 79.4635 41.6875 80.8594 41.6875Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip3_325_56)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M189.882 23.9166L185.424 29.2844L180.729 34.9397L175.774 40.9062L170.54 47.2104L165 53.8817V53.8828H125V13.8828H165V13.8817H125L131.671 8.34215L137.975 3.10746L143.942 -1.84688L149.597 -6.5428L154.965 -11H189.882V23.9166Z"
            fill="#474747"
          />
          <path
            d="M154.578 29.9531C154.578 31.3802 154.25 32.6458 153.594 33.75C152.948 34.8438 152.026 35.7031 150.828 36.3281C149.63 36.9427 148.203 37.25 146.547 37.25H141.078V45H136.469V22.9844H146.359C148.995 22.9844 151.021 23.5938 152.438 24.8125C153.865 26.0208 154.578 27.7344 154.578 29.9531ZM149.938 30.0312C149.938 27.7188 148.573 26.5625 145.844 26.5625H141.078V33.7031H145.969C147.24 33.7031 148.219 33.3906 148.906 32.7656C149.594 32.1302 149.938 31.2188 149.938 30.0312Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_325_56">
          <rect width="179" height="54" fill="white" />
        </clipPath>
        <clipPath id="clip1_325_56">
          <rect width="54" height="54" fill="white" />
        </clipPath>
        <clipPath id="clip2_325_56">
          <rect width="54" height="54" fill="white" transform="translate(60)" />
        </clipPath>
        <clipPath id="clip3_325_56">
          <rect
            width="54"
            height="54"
            fill="white"
            transform="translate(125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
