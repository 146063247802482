import React from 'react'
import { Timeline, Tween } from 'react-gsap'
import useWindowSize from '../../../../hook/useWindowSize'
import styles from './styles.module.scss'

export const TextAnimate = ({ totalProgress }: any) => {
  const [width] = useWindowSize()

  return (
    <div className={styles.sbl_text_wrap}>
      <Timeline totalProgress={totalProgress} paused>
        <Timeline totalProgress={totalProgress} pin={{ pushFollowers: true }}>
          <div className={styles.sbl_title_overflow}>
            <Timeline
              totalProgress={totalProgress}
              pin={{ pushFollowers: true }}>
              <Tween
                from={{ transform: 'translate(0px, 0vw)' }}
                to={{
                  transform: `translate(0px, -2vw)`,
                  opacity: 0,
                }}>
                <h2 className={styles.sbl_title}>О ателье:</h2>
              </Tween>
              <Tween
                from={{ transform: 'translate(0px, 1vw)' }}
                to={{
                  transform: `translate(0px, calc(-2vw - 5px))`,
                }}>
                <h2 className={styles.sbl_title}>О производстве:</h2>
              </Tween>
            </Timeline>
          </div>
          <Timeline totalProgress={totalProgress} pin={{ pushFollowers: true }}>
            <Tween
              from={{ width: 0 }}
              to={{
                width: '125px',
              }}>
              <div className={styles.progress} />
            </Tween>
          </Timeline>

          <Timeline totalProgress={totalProgress} pin={{ pushFollowers: true }}>
            <div className={styles.sbl_text}>
              <Tween
                from={{ transform: 'translate(0px, 0vw)' }}
                to={{
                  transform: `translate(0px, -5vw)`,
                  opacity: 0,
                }}>
                <div>
                  <p>
                    Технологическое лифтовое ателье «SBL» создает уникальную
                    атмосферу и комфорт, за счет индивидуальных дизайнерских и
                    технологических решений.
                  </p>
                  <p>
                    От замысла дизайнера до практической реализации. Воплощаем
                    самые дерзкие архитектурные планы!
                  </p>
                </div>
              </Tween>

              {width < 769 ? (
                <Tween
                  from={{ transform: 'translate(0px, -10vw)', opacity: 0 }}
                  to={{
                    transform:
                      width < 769
                        ? 'translate(0px, -138px)'
                        : `translate(0px, -193px)`,
                    opacity: 1,
                  }}>
                  <div>
                    <p>
                      Производство и конструкторское бюро, располагаются в
                      Технопарке, на базе завода по производству лифтов бизнес
                      класса Wellmaks.
                    </p>
                    <p>
                      HD MM система от технологического лифтового ателье «SBL»
                      собственной разработки, позволяет транслировать контент
                      разных форматов, доступ к системе осуществляется с помощью
                      личного кабинета, размеры дисплеев не ограничены.
                    </p>
                  </div>
                </Tween>
              ) : (
                <Tween
                  from={{ transform: 'translate(0px, -10vw)', opacity: 0 }}
                  to={{
                    transform:
                      width > 479
                        ? 'translate(0px, -18vw)'
                        : `translate(0px, -187px)`,
                    opacity: 1,
                  }}>
                  <div>
                    <p>
                      Производство и конструкторское бюро, располагаются в
                      Технопарке, на базе завода по производству лифтов бизнес
                      класса Wellmaks.
                    </p>
                    <p>
                      HD MM система от технологического лифтового ателье «SBL»
                      собственной разработки, позволяет транслировать контент
                      разных форматов, доступ к системе осуществляется с помощью
                      личного кабинета, размеры дисплеев не ограничены.
                    </p>
                  </div>
                </Tween>
              )}
            </div>
          </Timeline>
        </Timeline>
      </Timeline>
    </div>
  )
}
