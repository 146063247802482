import React from 'react'
import { Container, BreadCrumbs, ModalForm } from '../../../components'
import { ButtonModal } from '../../../fields'
import { premiumMock } from './premiumMock'
import { ElevatorsClassesTypes } from '../elevatorsClassesTypes'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import styles from './styles.module.scss'

export const Premium = () => {
  return (
    <Container>
      <div className={styles.elevator_info_wrap}>
        <BreadCrumbs />
        <div className={styles.elevator_info}>
          <span className={`${styles.elevator_page} ${styles.elevator_name}`}>
            Premium
          </span>
          <div className={styles.elevator_info_text}>
            <h5 className={styles.elevator_title}>
              Предлагаем Вашему вниманию Premium модели лифтов от производителя
              лифтов FUJI HD®. Компания FUJI HD® занимается производством
              премиальных линеек оборудования X-line® и Heritage®
            </h5>
            <p>
              Компания «Бюро Специальных Проектов» является официальным
              представителем производителя лифтового оборудования FUJI HD®
            </p>
          </div>
        </div>
        <div className={styles.elevator_wrap}>
          {premiumMock.map((item: ElevatorsClassesTypes) => (
            <div key={item.id}>
              <span className={styles.elevator_name}>{item.elevator_name}</span>

              <div className={styles.elevator}>
                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  className={`mySwiper ${styles.elevator_img}`}>
                  <SwiperSlide style={{ height: '100%' }}>
                    <img src={item.image} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={item.image2} alt="" />
                  </SwiperSlide>
                </Swiper>
                <div className={styles.elevator_text_wrap}>
                  <div className={styles.elevator_text}>
                    <span className={styles.type}>Дизайн</span>
                    <span className={styles.type_info}>{item.design}</span>
                  </div>
                  <div className={styles.elevator_text}>
                    <span className={styles.type}>Стены купе</span>
                    <span className={styles.type_info}>{item.walls}</span>
                  </div>
                  <div className={styles.elevator_text}>
                    <span className={styles.type}>Потолок</span>
                    <span className={styles.type_info}>{item.ceiling}</span>
                  </div>
                  <div className={styles.elevator_text}>
                    <span className={styles.type}>Пол</span>
                    <span className={styles.type_info}>{item.floor}</span>
                  </div>
                  <ButtonModal />
                  <ModalForm />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}
