import React, { useEffect } from 'react'

import { Container } from '../../components'
import { TitleH2 } from '../../fields'
import { privacyPolicyMock } from './privacyPolicyMock'
import styles from './styles.module.scss'

export const PrivacyPolicy: React.FC = () => {
  useEffect(() => {
    const scrollPage = window.scroll(0, 0)
    return () => scrollPage
  }, [])

  return (
    <Container>
      <div className={styles.policy_wrap}>
        <TitleH2 color="black">Политика конфиденциальности</TitleH2>
        <div className={styles.policy}>
          {privacyPolicyMock.map((item: any) => (
            <div key={item.id}>
              <span className={styles.policy_title}>{item.title}</span>
              {item.description}
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}
