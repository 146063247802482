import React from "react";
import { Link } from "react-router-dom";
import { FieldsProps } from "../types";

export const ButtonMailTo = ({ mailto, label, className }: FieldsProps) => {
  return (
    <Link
      to="#"
      className={className}
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};
