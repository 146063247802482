import React from "react";
import { IconProps } from "../types";

export const X5Icon = ({ width, height }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 226 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.67 57.2573L47.2914 46.6398L41.0481 57.2573H33.5766L43.1763 41.2668L33.3962 26.4297H41.2023L47.4718 36.3384L53.4449 26.4297H60.9168L51.2909 41.7565L61.1802 57.2573H53.67Z"
        fill="#474747"
      />
      <path
        d="M85.3637 46.61C85.3637 53.3746 80.2774 57.5624 73.6161 57.5624C67.7456 57.5624 62.8007 54.3411 62.3186 47.2542H68.9735C69.385 50.1468 71.6936 51.3904 74.2141 51.3904C77.3518 51.3904 78.7149 49.2836 78.7149 46.552C78.7149 43.6981 76.6125 41.92 73.6805 41.92C72.7437 41.8684 71.8102 42.0622 70.971 42.4822C70.1321 42.9026 69.4172 43.5348 68.8965 44.3165H62.4986L64.6075 26.4062H84.5986L83.9555 32.3141H69.623L69.0572 38.2154C69.0572 38.2154 70.5553 36.4438 75.1077 36.4438C80.5862 36.4438 85.3959 40.129 85.3959 46.6294"
        fill="#474747"
      />
      <path
        d="M62.8208 22.3299C59.4579 9.21284 48.8035 0 26.6973 0H0L3.6265 24.546C6.35283 46.7018 14.8082 53.8273 29.7708 56.4496C29.8403 56.4693 29.9136 56.4715 29.9842 56.4557C30.0548 56.4402 30.1204 56.4073 30.1752 56.3599C30.23 56.3128 30.2722 56.2528 30.2982 56.1851C30.3241 56.1176 30.3329 56.0444 30.3238 55.9728C30.323 55.877 30.2939 55.7838 30.2401 55.7044C30.1863 55.6254 30.1102 55.5641 30.0216 55.5283C19.9072 51.2634 18.7562 37.2701 16.5958 23.2254L14.924 12.0347H30.163C44.2125 12.0347 55.0598 11.8156 61.9272 22.6778C61.9736 22.7571 62.0389 22.8234 62.1175 22.8707C62.1964 22.918 62.2856 22.9447 62.3771 22.9483C62.4395 22.9475 62.5007 22.9343 62.5577 22.9094C62.615 22.8846 62.6665 22.8487 62.7097 22.8038C62.7525 22.7588 62.7863 22.7057 62.8089 22.6476C62.8311 22.5894 62.8417 22.5274 62.8401 22.4651C62.8472 22.4203 62.8472 22.3747 62.8401 22.3299"
        fill="#474747"
      />
      <path
        d="M121.835 41.1044C121.835 41.1044 123.43 58.1836 106.146 58.1836C97.0217 58.1836 89.994 51.2126 89.994 41.7422C89.994 32.2718 97.0668 25.3008 106.416 25.3008C113.631 25.3008 119.373 29.4433 121.565 35.8215H117.456C116.484 33.7105 114.916 31.9306 112.946 30.7012C110.977 29.4718 108.691 28.8464 106.371 28.9021C99.1886 28.9021 93.8711 34.3653 93.8711 41.8325C93.8711 49.0674 98.9188 54.6658 106.371 54.6658C112.64 54.6658 117.411 50.6651 117.945 44.3577H105.728V41.1364L121.835 41.1044Z"
        fill="#474747"
      />
      <path
        d="M141.466 32.1904V36.0559C140.935 35.9966 140.401 35.9666 139.865 35.9656C134.586 35.9656 130.921 39.3866 130.921 45.8163V57.5483H127.109V32.5898H130.896V38.2014C132.407 34.4582 135.435 32.0938 139.898 32.0938C140.418 32.0943 140.937 32.1244 141.454 32.184"
        fill="#474747"
      />
      <path
        d="M168.96 45.1158C168.938 47.6674 168.164 50.1558 166.734 52.2671C165.304 54.3788 163.282 56.0191 160.923 56.9814C158.564 57.9439 155.974 58.1856 153.478 57.6759C150.983 57.1662 148.694 55.9281 146.899 54.1175C145.104 52.3068 143.885 50.0045 143.393 47.5006C142.902 44.9967 143.161 42.4032 144.138 40.0464C145.115 37.6899 146.766 35.6754 148.883 34.2573C151 32.8389 153.489 32.08 156.036 32.0761C157.749 32.0469 159.45 32.365 161.037 33.0112C162.624 33.6573 164.065 34.6179 165.272 35.836C166.479 37.0541 167.428 38.5038 168.062 40.0983C168.696 41.6932 169.001 43.3996 168.96 45.1158ZM146.982 45.1158C146.982 50.6307 150.982 54.5539 156.036 54.5539C161.09 54.5539 165.134 50.6307 165.134 45.1158C165.134 39.6009 161.083 35.6773 156.036 35.6773C150.988 35.6773 146.982 39.5428 146.982 45.1158Z"
        fill="#474747"
      />
      <path
        d="M195.093 32.5897V57.5482H191.286V52.6001C190.485 54.2853 189.21 55.6989 187.617 56.6673C186.025 57.6357 184.185 58.1163 182.323 58.0505C176.748 58.0505 173.411 54.5137 173.411 48.4256V32.5897H177.192V47.7811C177.192 52.2459 179.295 54.5718 183.403 54.5718C188.386 54.5718 191.261 51.0153 191.261 45.4556V32.5703L195.093 32.5897Z"
        fill="#474747"
      />
      <path
        d="M226 45.1092C226 52.6664 221.043 58.1232 214.156 58.1232C212.19 58.1661 210.251 57.6716 208.545 56.6932C206.839 55.7148 205.431 54.2893 204.472 52.5696V68.9981H200.659V32.5914H204.472V37.7453C205.428 36.0063 206.839 34.5608 208.553 33.5653C210.267 32.5698 212.22 32.0615 214.201 32.0953C221.043 32.0953 226 37.5134 226 45.1157V45.1092ZM221.93 45.1157C221.93 39.646 218.387 35.6772 213.333 35.6772C208.279 35.6772 204.37 39.6008 204.37 45.1157C204.37 50.6306 208.227 54.5542 213.333 54.5542C218.438 54.5542 221.93 50.6306 221.93 45.1157Z"
        fill="#474747"
      />
    </svg>
  );
};
