import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Logo } from '../../../assets/icons'
import { useAppSelector, useAppDispatch } from '../../../hook/app'
import useWindowSize from '../../../hook/useWindowSize'
import { setBurger, setScrollPage } from '../../../store/appSlice'
import styles from './styles.module.scss'

export const HeaderMobile: React.FC = () => {
  const [width] = useWindowSize()
  const location = useLocation()
  const toggleMenu = useAppSelector(state => state.app.toggleMenu)
  const dispatch = useAppDispatch()

  const handleClick = () => {
    dispatch(setBurger(!toggleMenu))

    if (toggleMenu) {
      dispatch(setScrollPage(true))
    } else {
      dispatch(setScrollPage(false))
    }
  }

  const scrollClassesElevator = () => {
    dispatch(setScrollPage(true))
    dispatch(setBurger(false))
    if (location.pathname === '/') {
      document.querySelector('#elevator-classes')?.scrollIntoView({
        behavior: 'smooth',
      })
    } else {
      setTimeout(() => {
        document.querySelector('#elevator-classes')?.scrollIntoView({
          behavior: 'smooth',
        })
      }, 3100)
      location.pathname = '/'
    }
  }
  const scrollToContacts = () => {
    dispatch(setScrollPage(true))
    if (location.pathname === '/') {
      document.querySelector('#contacts')?.scrollIntoView({
        behavior: 'smooth',
      })
    } else {
      setTimeout(() => {
        document.querySelector('#contacts')?.scrollIntoView({
          behavior: 'smooth',
        })
      }, 3100)
      location.pathname = '/'
    }
  }

  return (
    <header className={styles.header}>
      <NavLink to="/">
        <Logo width={width > 479 ? 110 : 75} height={60} />
      </NavLink>
      <label>
        <input type="checkbox" readOnly checked={toggleMenu ? true : false} />
        <span className={styles.menu} onClick={handleClick}>
          <span className={styles.burger} />
          <span className={styles.burger} />
          <span className={styles.burger} />
        </span>
        <ul className={toggleMenu ? styles.burger_Open : styles.burger_Close}>
          <li>
            <NavLink
              to="/"
              className={styles.nav_link}
              onClick={scrollClassesElevator}>
              Классы лифтов
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/sbl-design"
              onClick={handleClick}
              className={styles.nav_link}>
              SBL Design
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              onClick={handleClick}
              className={styles.nav_link}>
              О компании
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/"
              className={styles.nav_link}
              onClick={scrollToContacts}>
              Контакты
            </NavLink>
          </li>
        </ul>
      </label>
    </header>
  )
}
